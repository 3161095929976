<h1 mat-dialog-title> Reclamation</h1>
<div mat-dialog-content>
<form [formGroup]="claimForm">
  <mat-form-field appearance="fill">
    <mat-label>Sujet de la demande</mat-label>
    <mat-select matInput [formControl]="claimForm.get('subject')">
      <mat-option value="BUG">PROBLEM/BUG</mat-option>
      <mat-option value="REFUND">REFUND</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="example-full-width">
    <mat-label>Corps du message</mat-label>
    <textarea rows="5" cols="40" placeholder="..." matInput [formControl]="claimForm.get('body')"></textarea>
  </mat-form-field>
 </form>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="secondary" [mat-dialog-close]="claimForm.getRawValue()">Envoyer</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="false">Annuler</button>
</div>

import {Injectable} from '@angular/core';

@Injectable()
export class CountriesService {
  getStates() {
    return [
      { value: 'FR', name: 'France', eu: true},
      { value: 'BE', name: 'Belgique', eu: true},
      { value: 'LU', name: 'Luxembourg', eu: true},
      { value: 'AF', name: 'Afghanistan', eu: false},
      { value: 'ZA', name: 'Afrique du Sud', eu: false},
      { value: 'AL', name: 'Albanie', eu: false},
      { value: 'DZ', name: 'Algérie', eu: false},
      { value: 'DE', name: 'Allemagne', eu: true},
      { value: 'AD', name: 'Andorre', eu: false},
      { value: 'AO', name: 'Angola', eu: false},
      { value: 'AI', name: 'Anguilla', eu: false},
      { value: 'AQ', name: 'Antarctique', eu: false},
      { value: 'AG', name: 'Antigua-et-Barbuda', eu: false},
      { value: 'AN', name: 'Antilles néerlandaises', eu: false},
      { value: 'SA', name: 'Arabie saoudite', eu: false},
      { value: 'AR', name: 'Argentine', eu: false},
      { value: 'AM', name: 'Arménie', eu: false},
      { value: 'AW', name: 'Aruba', eu: false},
      { value: 'AU', name: 'Australie', eu: false},
      { value: 'AT', name: 'Autriche', eu: true},
      { value: 'AZ', name: 'Azerbaïdjan', eu: false},
      { value: 'BS', name: 'Bahamas', eu: false},
      { value: 'BH', name: 'Bahreïn', eu: false},
      { value: 'BD', name: 'Bangladesh', eu: false},
      { value: 'BB', name: 'Barbade', eu: false},
      { value: 'BY', name: 'Bélarus', eu: false},
      { value: 'BZ', name: 'Belize', eu: false},
      { value: 'BJ', name: 'Bénin', eu: false},
      { value: 'BM', name: 'Bermudes', eu: false},
      { value: 'BT', name: 'Bhoutan', eu: false},
      { value: 'BO', name: 'Bolivie', eu: false},
      { value: 'BA', name: 'Bosnie-Herzégovine', eu: false},
      { value: 'BW', name: 'Botswana', eu: false},
      { value: 'BR', name: 'Brésil', eu: false},
      { value: 'BN', name: 'Brunéi Darussalam', eu: false},
      { value: 'BG', name: 'Bulgarie', eu: true},
      { value: 'BF', name: 'Burkina Faso', eu: false},
      { value: 'BI', name: 'Burundi', eu: false},
      { value: 'KH', name: 'Cambodge', eu: false},
      { value: 'CM', name: 'Cameroun', eu: false},
      { value: 'CA', name: 'Canada', eu: false},
      { value: 'CV', name: 'Cap-Vert', eu: false},
      { value: 'EA', name: 'Ceuta et Melilla', eu: false},
      { value: 'CL', name: 'Chili', eu: false},
      { value: 'CN', name: 'Chine', eu: false},
      { value: 'CY', name: 'Chypre', eu: true},
      { value: 'CO', name: 'Colombie', eu: false},
      { value: 'KM', name: 'Comores', eu: false},
      { value: 'CG', name: 'Congo-Brazzaville', eu: false},
      { value: 'KP', name: 'Corée du Nord', eu: false},
      { value: 'KR', name: 'Corée du Sud', eu: false},
      { value: 'CR', name: 'Costa Rica', eu: false},
      { value: 'CI', name: 'Côte d’Ivoire', eu: false},
      { value: 'HR', name: 'Croatie', eu: true},
      { value: 'CU', name: 'Cuba', eu: false},
      { value: 'DK', name: 'Danemark', eu: true},
      { value: 'DG', name: 'Diego Garcia', eu: false},
      { value: 'DJ', name: 'Djibouti', eu: false},
      { value: 'DM', name: 'Dominique', eu: false},
      { value: 'EG', name: 'Égypte', eu: false},
      { value: 'SV', name: 'El Salvador', eu: false},
      { value: 'AE', name: 'Émirats arabes unis', eu: false},
      { value: 'EC', name: 'Équateur', eu: false},
      { value: 'ER', name: 'Érythrée', eu: false},
      { value: 'ES', name: 'Espagne', eu: true},
      { value: 'EE', name: 'Estonie', eu: true},
      { value: 'VA', name: 'État de la Cité du Vatican', eu: false},
      { value: 'FM', name: 'États fédérés de Micronésie', eu: false},
      { value: 'US', name: 'États-Unis', eu: false},
      { value: 'ET', name: 'Éthiopie', eu: false},
      { value: 'FJ', name: 'Fidji', eu: false},
      { value: 'FI', name: 'Finlande', eu: true},
      { value: 'GA', name: 'Gabon', eu: false},
      { value: 'GM', name: 'Gambie', eu: false},
      { value: 'GE', name: 'Géorgie', eu: false},
      { value: 'GS', name: 'Géorgie du Sud et les îles Sandwich du Sud', eu: false},
      { value: 'GH', name: 'Ghana', eu: false},
      { value: 'GI', name: 'Gibraltar', eu: false},
      { value: 'GR', name: 'Grèce', eu: true},
      { value: 'GD', name: 'Grenade', eu: false},
      { value: 'GL', name: 'Groenland', eu: false},
      { value: 'GP', name: 'Guadeloupe', eu: false},
      { value: 'GU', name: 'Guam', eu: false},
      { value: 'GT', name: 'Guatemala', eu: false},
      { value: 'GG', name: 'Guernesey', eu: false},
      { value: 'GN', name: 'Guinée', eu: false},
      { value: 'GQ', name: 'Guinée équatoriale', eu: false},
      { value: 'GW', name: 'Guinée-Bissau', eu: false},
      { value: 'GY', name: 'Guyana', eu: false},
      { value: 'GF', name: 'Guyane française', eu: false},
      { value: 'HT', name: 'Haïti', eu: false},
      { value: 'HN', name: 'Honduras', eu: false},
      { value: 'HU', name: 'Hongrie', eu: true},
      { value: 'BV', name: 'Île Bouvet', eu: false},
      { value: 'CX', name: 'Île Christmas', eu: false},
      { value: 'CP', name: 'Île Clipperton', eu: false},
      { value: 'AC', name: 'Île de l\'Ascension', eu: false},
      { value: 'IM', name: 'Île de Man', eu: false},
      { value: 'NF', name: 'Île Norfolk', eu: false},
      { value: 'AX', name: 'Îles Åland', eu: false},
      { value: 'KY', name: 'Îles Caïmans', eu: false},
      { value: 'IC', name: 'Îles Canaries', eu: false},
      { value: 'CC', name: 'Îles Cocos - Keeling', eu: false},
      { value: 'CK', name: 'Îles Cook', eu: false},
      { value: 'FO', name: 'Îles Féroé', eu: false},
      { value: 'HM', name: 'Îles Heard et MacDonald', eu: false},
      { value: 'FK', name: 'Îles Malouines', eu: false},
      { value: 'MP', name: 'Îles Mariannes du Nord', eu: false},
      { value: 'MH', name: 'Îles Marshall', eu: false},
      { value: 'UM', name: 'Îles Mineures Éloignées des États-Unis', eu: false},
      { value: 'SB', name: 'Îles Salomon', eu: false},
      { value: 'TC', name: 'Îles Turks et Caïques', eu: false},
      { value: 'VG', name: 'Îles Vierges britanniques', eu: false},
      { value: 'VI', name: 'Îles Vierges des États-Unis', eu: false},
      { value: 'IN', name: 'Inde', eu: false},
      { value: 'ID', name: 'Indonésie', eu: false},
      { value: 'IQ', name: 'Irak', eu: false},
      { value: 'IR', name: 'Iran', eu: false},
      { value: 'IE', name: 'Irlande', eu: true},
      { value: 'IS', name: 'Islande', eu: false},
      { value: 'IL', name: 'Israël', eu: false},
      { value: 'IT', name: 'Italie', eu: true},
      { value: 'JM', name: 'Jamaïque', eu: false},
      { value: 'JP', name: 'Japon', eu: false},
      { value: 'JE', name: 'Jersey', eu: false},
      { value: 'JO', name: 'Jordanie', eu: false},
      { value: 'KZ', name: 'Kazakhstan', eu: false},
      { value: 'KE', name: 'Kenya', eu: false},
      { value: 'KG', name: 'Kirghizistan', eu: false},
      { value: 'KI', name: 'Kiribati', eu: false},
      { value: 'KW', name: 'Koweït', eu: false},
      { value: 'LA', name: 'Laos', eu: false},
      { value: 'LS', name: 'Lesotho', eu: false},
      { value: 'LV', name: 'Lettonie', eu: true},
      { value: 'LB', name: 'Liban', eu: false},
      { value: 'LR', name: 'Libéria', eu: false},
      { value: 'LY', name: 'Libye', eu: false},
      { value: 'LI', name: 'Liechtenstein', eu: false},
      { value: 'LT', name: 'Lituanie', eu: true},
      { value: 'MK', name: 'Macédoine', eu: false},
      { value: 'MG', name: 'Madagascar', eu: false},
      { value: 'MY', name: 'Malaisie', eu: false},
      { value: 'MW', name: 'Malawi', eu: false},
      { value: 'MV', name: 'Maldives', eu: false},
      { value: 'ML', name: 'Mali', eu: false},
      { value: 'MT', name: 'Malte', eu: true},
      { value: 'MA', name: 'Maroc', eu: false},
      { value: 'MQ', name: 'Martinique', eu: false},
      { value: 'MU', name: 'Maurice', eu: false},
      { value: 'MR', name: 'Mauritanie', eu: false},
      { value: 'YT', name: 'Mayotte', eu: false},
      { value: 'MX', name: 'Mexique', eu: false},
      { value: 'MD', name: 'Moldavie', eu: false},
      { value: 'MC', name: 'Monaco', eu: false},
      { value: 'MN', name: 'Mongolie', eu: false},
      { value: 'ME', name: 'Monténégro', eu: false},
      { value: 'MS', name: 'Montserrat', eu: false},
      { value: 'MZ', name: 'Mozambique', eu: false},
      { value: 'MM', name: 'Myanmar', eu: false},
      { value: 'NA', name: 'Namibie', eu: false},
      { value: 'NR', name: 'Nauru', eu: false},
      { value: 'NP', name: 'Népal', eu: false},
      { value: 'NI', name: 'Nicaragua', eu: false},
      { value: 'NE', name: 'Niger', eu: false},
      { value: 'NG', name: 'Nigéria', eu: false},
      { value: 'NU', name: 'Niue', eu: false},
      { value: 'NO', name: 'Norvège', eu: false},
      { value: 'NC', name: 'Nouvelle-Calédonie', eu: false},
      { value: 'NZ', name: 'Nouvelle-Zélande', eu: false},
      { value: 'OM', name: 'Oman', eu: false},
      { value: 'UG', name: 'Ouganda', eu: false},
      { value: 'UZ', name: 'Ouzbékistan', eu: false},
      { value: 'PK', name: 'Pakistan', eu: false},
      { value: 'PW', name: 'Palaos', eu: false},
      { value: 'PA', name: 'Panama', eu: false},
      { value: 'PG', name: 'Papouasie-Nouvelle-Guinée', eu: false},
      { value: 'PY', name: 'Paraguay', eu: false},
      { value: 'NL', name: 'Pays-Bas', eu: true},
      { value: 'PE', name: 'Pérou', eu: false},
      { value: 'PH', name: 'Philippines', eu: false},
      { value: 'PN', name: 'Pitcairn', eu: false},
      { value: 'PL', name: 'Pologne', eu: true},
      { value: 'PF', name: 'Polynésie française', eu: false},
      { value: 'PR', name: 'Porto Rico', eu: false},
      { value: 'PT', name: 'Portugal', eu: true},
      { value: 'QA', name: 'Qatar', eu: false},
      { value: 'HK', name: 'R.A.S. chinoise de Hong Kong', eu: false},
      { value: 'MO', name: 'R.A.S. chinoise de Macao', eu: false},
      { value: 'QO', name: 'régions éloignées de l’Océanie', eu: false},
      { value: 'CF', name: 'République centrafricaine', eu: false},
      { value: 'CD', name: 'République démocratique du Congo', eu: false},
      { value: 'DO', name: 'République dominicaine', eu: false},
      { value: 'CZ', name: 'République tchèque', eu: true},
      { value: 'RE', name: 'Réunion', eu: false},
      { value: 'RO', name: 'Roumanie', eu: true},
      { value: 'GB', name: 'Royaume-Uni', eu: false},
      { value: 'RU', name: 'Russie', eu: false},
      { value: 'RW', name: 'Rwanda', eu: false},
      { value: 'EH', name: 'Sahara occidental', eu: false},
      { value: 'BL', name: 'Saint-Barthélémy', eu: false},
      { value: 'KN', name: 'Saint-Kitts-et-Nevis', eu: false},
      { value: 'SM', name: 'Saint-Marin', eu: false},
      { value: 'MF', name: 'Saint-Martin', eu: false},
      { value: 'PM', name: 'Saint-Pierre-et-Miquelon', eu: false},
      { value: 'VC', name: 'Saint-Vincent-et-les Grenadines', eu: false},
      { value: 'SH', name: 'Sainte-Hélène', eu: false},
      { value: 'LC', name: 'Sainte-Lucie', eu: false},
      { value: 'WS', name: 'Samoa', eu: false},
      { value: 'AS', name: 'Samoa américaines', eu: false},
      { value: 'ST', name: 'Sao Tomé-et-Principe', eu: false},
      { value: 'SN', name: 'Sénégal', eu: false},
      { value: 'RS', name: 'Serbie', eu: false},
      { value: 'CS', name: 'Serbie-et-Monténégro', eu: false},
      { value: 'SC', name: 'Seychelles', eu: false},
      { value: 'SL', name: 'Sierra Leone', eu: false},
      { value: 'SG', name: 'Singapour', eu: false},
      { value: 'SK', name: 'Slovaquie', eu: true},
      { value: 'SI', name: 'Slovénie', eu: true},
      { value: 'SO', name: 'Somalie', eu: false},
      { value: 'SD', name: 'Soudan', eu: false},
      { value: 'LK', name: 'Sri Lanka', eu: false},
      { value: 'SE', name: 'Suède', eu: true},
      { value: 'CH', name: 'Suisse', eu: false},
      { value: 'SR', name: 'Suriname', eu: false},
      { value: 'SJ', name: 'Svalbard et Île Jan Mayen', eu: false},
      { value: 'SZ', name: 'Swaziland', eu: false},
      { value: 'SY', name: 'Syrie', eu: false},
      { value: 'TJ', name: 'Tadjikistan', eu: false},
      { value: 'TW', name: 'Taïwan', eu: false},
      { value: 'TZ', name: 'Tanzanie', eu: false},
      { value: 'TD', name: 'Tchad', eu: false},
      { value: 'TF', name: 'Terres australes françaises', eu: false},
      { value: 'IO', name: 'Territoire britannique de l\'océan Indien', eu: false},
      { value: 'PS', name: 'Territoire palestinien', eu: false},
      { value: 'TH', name: 'Thaïlande', eu: false},
      { value: 'TL', name: 'Timor oriental', eu: false},
      { value: 'TG', name: 'Togo', eu: false},
      { value: 'TK', name: 'Tokelau', eu: false},
      { value: 'TO', name: 'Tonga', eu: false},
      { value: 'TT', name: 'Trinité-et-Tobago', eu: false},
      { value: 'TA', name: 'Tristan da Cunha', eu: false},
      { value: 'TN', name: 'Tunisie', eu: false},
      { value: 'TM', name: 'Turkménistan', eu: false},
      { value: 'TR', name: 'Turquie', eu: false},
      { value: 'TV', name: 'Tuvalu', eu: false},
      { value: 'UA', name: 'Ukraine', eu: false},
      { value: 'EU', name: 'Union européenne', eu: false},
      { value: 'UY', name: 'Uruguay', eu: false},
      { value: 'VU', name: 'Vanuatu', eu: false},
      { value: 'VE', name: 'Venezuela', eu: false},
      { value: 'VN', name: 'Viêt Nam', eu: false},
      { value: 'WF', name: 'Wallis-et-Futuna', eu: false},
      { value: 'YE', name: 'Yémen', eu: false},
      { value: 'ZM', name: 'Zambie', eu: false},
      { value: 'ZW', name: 'Zimbabwe', eu: false}
    ];
  }
}

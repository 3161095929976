import { AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PlanService} from '../../api/plan.service.component';
import {Plan} from '../../api/api.model';
import {environment} from '../../../environments/environment';
declare var Stripe: any;


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit, AfterViewInit {

  public yearly: Boolean;
  public planId: string;
  public selectedPlan: Plan;
  public totalAmount: number;
  public per: string

  constructor(private route: ActivatedRoute,
              private planService: PlanService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.planId = params['planId'];
      this.yearly = (params['yearly'] === "true");

      this.planService.getAll()
        .subscribe(list => {
          this.selectedPlan = list.filter(plan => plan.name === this.planId)[0];
          if (this.yearly === true) {
            this.per = 'an';
            this.totalAmount = this.selectedPlan.monthlyEquivalentPrice * 12 ;
          } else {
            this.per = 'mois'
            this.totalAmount = this.selectedPlan.price;
          }
        });
    });



  }



  public ngAfterViewInit() {
    const stripe = Stripe(environment.stripePublicKey);
    const checkoutButton = document.getElementById('checkout-button');
    const self = this;

    checkoutButton.addEventListener('click', function(event) {
      // Create a new Checkout Session using the server-side endpoint you
      // created in step 3.
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', `Bearer ` + localStorage.getItem('token'));

      const paymentQuery = {
        planId: self.planId,
        yearly : self.yearly,
      };
      fetch('api/payment/create-checkout-session', {
        method: 'POST',
        headers : myHeaders,
        body : JSON.stringify(paymentQuery)
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(session) {
          return stripe.redirectToCheckout({ sessionId: session.id });
        })
        .then(function(result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          if (result.error) {
            alert(result.error.message);
          }
        })
        .catch(function(error) {
        });
    });
  }


}

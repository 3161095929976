import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-closing',
  templateUrl: './closing.component.html',
  styleUrls: ['./closing.component.css']
})
export class ClosingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, retry} from 'rxjs/operators';
import {request} from 'http';
import {throwError} from 'rxjs';
import {Router} from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor (private router: Router) {}

      intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
          .pipe(
            catchError((error: HttpErrorResponse) => {
              let errorMessage = '';
              if (error.error instanceof ErrorEvent) {
                // client-side error
                errorMessage = `Error: ${error.error.message}`;
              } else {
                // server-side error
                errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                if (error.status === 403) {
                  if (this.router.url.indexOf('login') === -1 ) {
                    this.router.navigateByUrl('/login');
                  }
                }
              }
              return throwError(error);
            })
          );
      }
    }

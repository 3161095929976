/**
 * Created by walid on 10/07/17.
 */

import { Injectable } from '@angular/core';

@Injectable()
export class Configuration {
  //public Server: string = 'http://localhost:8080/';
   public Server: string = '/';
  public ApiUrl: string = 'api/';
  public ServerWithApiUrl = this.Server + this.ApiUrl;
}

<div *ngIf="myTest" id="bodyid" class="my-alternate-theme">
  <b>{{myTest.thema}}</b>
  <div >
    <div >
      <app-step  *ngIf="currentQuestion" [question] ="currentQuestion" [myResponse]="myResponse"></app-step>
    </div>

  </div>



  <div class="footer">
    <mat-progress-bar *ngIf="numberOfQuestions" mode="determinate" [value]="computeProgress()"></mat-progress-bar>
    <button  mat-icon-button class='leftRs' (click)="previous()">
      <i class="material-icons bigArrow">navigate_before</i>
    </button>
    <button  mat-icon-button class='rightRs' (click)="next()">
      <i class="material-icons bigArrow">navigate_next</i>
    </button>
    <div class="center-align">
      <button class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect  mdl-button--colored"
              [disabled]="!checkCompleted(myResponse)" (click)="clicked(myTest.testId,token,myResponse)">
        Soumettre mes réponses
      </button>
    </div>
  </div>


</div>

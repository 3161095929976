import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TestService} from '../api/test.service.component';
import { UserService} from '../api/user.service.component';
import {Configuration} from '../api/api.configuration.component';
import { TestSummary, User } from '../api/api.model';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionService } from 'app/api/subscription.service';
import { PermissionService } from 'app/api/permission.service';
import {AuthenticationService} from '../api/authentication.service';
import {ClientService} from "../api/client.service.component";
declare var componentHandler: any;

@Component({
  selector: 'board',
  // providers : [Configuration , TestService,UserService],
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})



export class BoardComponent implements AfterViewInit, OnInit{

  public connectedClientEmail: String;

   constructor(public router: Router,
              private route: ActivatedRoute,
              private subscriptionService: SubscriptionService,
              private permissionService: PermissionService,
              private authenticationService:  AuthenticationService,
              private clientService : ClientService
   ) {
   }

   ngAfterViewInit(): void {
     componentHandler.upgradeAllRegistered();
  }

  public logout() {
     this.authenticationService.logout();
     this.router.navigateByUrl('/');
  }

  ngOnInit() {
    const userId: number =  parseInt(localStorage.getItem('currentUser'),10) ;

    this.subscriptionService.getSubscriptionsByClientId().subscribe(subscription => {
      if (!subscription) {
        this.router.navigateByUrl('/dashboard/plans');
      }else {
      }
    });

    this.clientService.getById(userId).subscribe(client => {
      this.connectedClientEmail = client.email;
    });
  }

}



<mat-card class="example-container">
  <mat-card-header>
    <mat-card-title>
      <button mat-icon-button
              matTooltip="Add candidat"
              (click)="addCandidat();">
        <i class="material-icons">person_add</i></button>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="example-container mat-elevation-z8">
      <mat-table #table [dataSource]="dataSource" matSort matSortActive="creationDate" matSortDirection="desc">

        <!-- Position Column -->
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
          <mat-cell *matCellDef="let candidat"> {{candidat.email}} </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="lastname">
          <mat-header-cell [hidden]="isMobile"  *matHeaderCellDef mat-sort-header> Nom </mat-header-cell>
          <mat-cell [hidden]="isMobile" *matCellDef="let candidat"> {{candidat.lastname}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="creationDate">
          <mat-header-cell [hidden]="isMobile"  *matHeaderCellDef mat-sort-header> Date de création </mat-header-cell>
          <mat-cell [hidden]="isMobile"   *matCellDef="let candidat"> {{candidat.creationDate | date: 'dd/MM/yyyy HH:mm' }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell  *matHeaderCellDef >  </mat-header-cell>
          <mat-cell *matCellDef="let candidat">
            <button mat-icon-button
                    matTooltip="Delete candidat"
                    (click)="delete(candidat)">
              <i class="material-icons">delete</i>
            </button>
            <button mat-icon-button
                    matTooltip="Edit candidat"
                    (click)="modify(candidat)">
              <i class="material-icons">edit</i>
            </button>
          </mat-cell>
        </ng-container>





        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>


      </mat-table>

      <mat-paginator #paginator
                     [pageSize]="5"
                     [pageSizeOptions]="[5, 10, 20]"
                     [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>

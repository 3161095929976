     <div id="bodyid" class="demo-blog demo-blog--blogpost mdl-layout mdl-js-layout has-drawer is-upgraded">
      <div class="mdl-layout__content">
       <div class="room-blog__posts mdl-grid">
          <div class="shadowed mdl-card mdl-shadow--4dp mdl-cell mdl-cell--12-col">
              <div *ngIf="invitation.consumed;else content" class="whitefont"> Vous avez déjà passé ce test. Vous ne pouvez plus y accéder</div>
              <ng-template #content>
                 <div class="whitefont">
                  <p>
                    Vous avez été invité pour passer un Test en ligne afin d'évaluer vos compétences techniques.
                    <br>
                    Nous vous prions de consacrer du temps pour répondre à ces questions.
                    <br>
                    Le temps de passage est comptabilisé.
                  </p>
                 </div>
              <button *ngIf="invitation && token" class="mdl-button mdl-js-button mdl-button--fab mdl-button--colored"
                      [routerLink]="['/testroom/',invitation.testId]"
                      [queryParams]="{token:token}"  >
                 <i class="material-icons">send</i>
              </button>

              </ng-template>
          </div>
        </div>
      </div>
    </div>


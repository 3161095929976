import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {FormControl} from '@angular/forms';
import {SearchService} from 'app/dashboard/testlist/search.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public isMobile: boolean;
  isBackToResultList: boolean;
  title = 'Catalogue des Tests';
  isSearcheable = true;
  queryField: FormControl = new FormControl();

  constructor(public router: Router, private activeRoute: ActivatedRoute, public searchService: SearchService) {
  }

  ngOnInit() {

    this.isMobile = (window.innerWidth <= 700);
    window.onresize = () => this.isMobile = (window.innerWidth <= 700);

    this.router.events.subscribe(this.onUrlChange.bind(this));
    this.queryField.valueChanges.subscribe(v => {
      this.searchService.setSearchData(v);
    });
    this.computeTitle(this.router.url);


  }

  onUrlChange(ev) {
    if (ev instanceof NavigationEnd) {
      const url = ev.url;
      this.computeTitle(url);

    }
  }

  private computeTitle(url: any) {
    if (url.indexOf('testlist') !== -1) {
      if (this.isMobile) {
        this.title = 'Catalogue';
      } else {
        this.title = 'Catalogue des Tests';
      }
      this.isSearcheable = true;
      this.isBackToResultList = false;
    } else if (url.indexOf('invitationlist') !== -1) {
      this.title = 'Invitations en Attente';
      this.isSearcheable = false;
      this.isBackToResultList = false;
    } else if (url.indexOf('resultlist') !== -1) {
      this.title = 'Résultats';
      this.isSearcheable = false;
      this.isBackToResultList = false;
    } else if (url.indexOf('candidatlist') !== -1) {
      this.title = 'Liste des Candidats';
      this.isSearcheable = false;
      this.isBackToResultList = false;
    } else if (url.indexOf('resultreport') !== -1) {
      this.title = 'Compte rendu';
      this.isSearcheable = false;
      this.isBackToResultList = true;
    } else if (url.indexOf('detailed-answers') !== -1) {
      this.title = 'Réponses Détaillées';
      this.isSearcheable = false;
      this.isBackToResultList = true;
    } else if (url.indexOf('my-subscriptions') !== -1) {
      this.title = 'Mes Souscriptions';
      this.isSearcheable = false;
      this.isBackToResultList = false;
    } else if (url.indexOf('faq') !== -1) {
      this.title = 'F.A.Q';
      this.isSearcheable = false;
      this.isBackToResultList = false;
    } else if (url.indexOf('client-info') !== -1) {
      this.title = 'Formulaire de Souscription';
      this.isSearcheable = false;
      this.isBackToResultList = false;
    } else if (url.indexOf('plans') !== -1) {
      this.title = 'Nos Offres';
      this.isSearcheable = false;
      this.isBackToResultList = false;
    } else {
      this.title = 'Tableau de bord';
      this.isSearcheable = false;
      this.isBackToResultList = false;
    }
  }


}

import { Injectable } from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import { Configuration } from 'app/api/api.configuration.component';
import { Client } from 'app/api/api.model';
import jwtDecode from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { SubscriptionWorkflowService, Prospect } from 'app/api/subscription.workflow.service';
import { SubscriptionService } from './subscription.service';
import { PermissionService } from './permission.service';

@Injectable()
export class AuthenticationService {
     private actionUrl: string;
     private someProspect: Prospect;

    constructor(private httpClient: HttpClient,
        private conf: Configuration,
        private subscriptionService: SubscriptionService,
        private permissionService: PermissionService,
        private workflow: SubscriptionWorkflowService) {
        this.actionUrl = conf.ApiUrl;
     }


     public login =  (login: string, password: string ): Observable<any> => {
    return this.httpClient.post(this.actionUrl + 'authentication', {login, password }, {responseType: 'text'})
    //   .map( (resp : Response) =>  resp.text() )
     .pipe(
       map(jwt => {
                localStorage.removeItem('token');
                // login successful if there's a jwt token in the response
                const decoded: any = jwtDecode(jwt);
                if (jwt && decoded.id) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', decoded.id);
                    localStorage.setItem('token', jwt);
                    this.workflow.getProspect().pipe(take(1)).subscribe(e => this.someProspect = e ) ;
                    this.someProspect.clientId = decoded.id;
                    this.workflow.setProspect(this.someProspect);
                }
                return jwt;
            })
     );
  }




  public otpLogin = (token: string): Observable<any> => {
    this.logout();
    return this.httpClient.post(this.actionUrl + 'authentication/otp?token=' + token, null, {responseType: 'text'})
      .pipe(map(jwt => {
        localStorage.removeItem('token');
        // login successful if there's a jwt token in the response
        const decoded = jwtDecode(jwt);
        if (jwt) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.setItem('currentCandidat', decoded.email);
          localStorage.setItem('token', jwt);

        }
        return jwt;
      }));
  }


    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        localStorage.clear();
    }

     public isAuthenticated(): boolean {

            const token = localStorage.getItem('token');

            // Check whether the token is expired and return
            // true or false
            return !this.isTokenExpired(token);
        }


  public resetPassword(email: string): Observable<any> {
      return this.httpClient
        .post(this.actionUrl + '/authentication/password/init?mail=' + email, null, {responseType: 'text'});

  }

  public finishResetPassword(key: string, pwd: string): Observable<any> {
      return this.httpClient
        .post(this.actionUrl + '/authentication/password/finish',
              {key: key, password : pwd},
              {responseType: 'text'}
              );

  }

    getTokenExpirationDate(token: string): Date {
    const decoded: any = jwtDecode(token);
    if (decoded.exp === undefined) { return null; }
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) { return true; }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) { return false; }
    return !(date.valueOf() > new Date().valueOf());
  }

  getRole(token: string) {
       const decoded: any = jwtDecode(token);
       return decoded.ROLE;
  }


}

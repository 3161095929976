  <div class="snip1214">
    <div *ngIf="offerFreePlan" class="plan">
      <h3 class="plan-title">
        Starter
      </h3>
      <div class="plan-cost"><span class="plan-price">Gratuit</span><span class="plan-type"></span></div>
      <ul class="plan-features">
        <li><i class="ion-checkmark"> </i>14 jours d'essai</li>
        <li><i class="ion-checkmark"> </i>3 Invitations Maxi</li>
        <li><i class="ion-close"> </i>Réponses détaillées</li>
        <li><i class="ion-close"> </i>Export PDF</li>
        <li><i class="ion-close"> </i>Support SAV</li>
        <li><i class="ion-close"> </i>Backups quotidiens</li>
      </ul>
      <div class="plan-select"><a (click)="chooseOffer('NONE')" >Sélectionner</a></div>
    </div>
    <div class="plan featured">
      <h3 class="plan-title">
        Solo
      </h3>
      <div class="plan-cost">
        <span class="plan-price">{{mainPlanEquivalentPrice}}€</span>
        <span class="plan-type">/ mois</span>
      </div>
      <div>
        Mensuel
        <mat-slide-toggle [checked]="yearly" (change)="toggle($event)">
        </mat-slide-toggle>
        Annuel (<span class="promo">-50%</span>)
      </div>
      <ul class="plan-features">
        <li><i class="ion-checkmark"> </i>Invitations illimitées</li>
        <li><i class="ion-checkmark"> </i>Graphiques du profile</li>
        <li><i class="ion-checkmark"> </i>Réponses détaillées</li>
        <li><i class="ion-checkmark"> </i>Export PDF</li>
        <li><i class="ion-checkmark"> </i>Support SAV</li>
        <li><i class="ion-checkmark"> </i>Backups quotidiens</li>
      </ul>
<!--      <div id="checkout-button" class="plan-select" data-id='Solo'><a>Sélectionner</a></div>-->
      <div class="plan-select"><a (click)="chooseOffer('SOLO')" >Sélectionner</a></div>

    </div>
    <div class="plan">
      <h3 class="plan-title">
        Entreprise
      </h3>
      <div class="plan-cost"><span class="plan-price">?</span><span class="plan-type">/ Monthly</span></div>
      <ul class="plan-features">
        <li><i class="ion-checkmark"> </i>Marque blanche</li>
        <li><i class="ion-checkmark"> </i>Questionnaires dédiés</li>
        <li><i class="ion-checkmark"> </i>Invitations illimitées</li>
        <li><i class="ion-checkmark"> </i>Graphiques du profile</li>
        <li><i class="ion-checkmark"> </i>Réponses détaillées</li>
        <li><i class="ion-checkmark"> </i>Export PDF</li>
      </ul>
      <div class="plan-select"><a href="">Nous contacter</a></div>
    </div>
  </div>

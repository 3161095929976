import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { Configuration } from 'app/api/api.configuration.component';
import { AuthenticationService } from 'app/api/authentication.service';
import { ClientService } from 'app/api/client.service.component';
import { JwtInterceptor } from 'app/api/jwt.interceptor';
import { PermissionService } from 'app/api/permission.service';
import { PurchaseService } from 'app/api/purchase.service';
import { RoleGuardService as RoleGuard } from 'app/api/roleGuardService.service';
import { SubscriptionService } from 'app/api/subscription.service';
import { SubscriptionWorkflowService } from 'app/api/subscription.workflow.service';
import { AddCandidatDialogComponent } from 'app/dashboard/testlist/add-candidat-dialog/add-candidat-dialog.component';
import { SearchService } from 'app/dashboard/testlist/search.service';
import { GaugesModule } from 'ng-canvas-gauges';
import { ChartsModule } from 'ng2-charts';
import { ClaimService } from './api/claim.service';
import { ErrorInterceptor } from './api/error.interceptor';
import { InvitationService } from './api/invitation.service.component';
import { PassageService } from './api/passage.service.component';
import { PlanService } from './api/plan.service.component';
import { TestService } from './api/test.service.component';
import { UserService } from './api/user.service.component';
import { AppComponent } from './app.component';
import { BoardComponent } from './dashboard/board.component';
import { CandidatlistComponent } from './dashboard/candidatlist/candidatlist.component';
import { ClaimCreationDialogComponent } from './dashboard/frequently-asked-questions/claim-creation-dialog/claim-creation-dialog.component';
import { FrequentlyAskedQuestionsComponent } from './dashboard/frequently-asked-questions/frequently-asked-questions.component';
import { HeaderComponent } from './dashboard/header/header.component';
import { InvitationlistComponent } from './dashboard/invitationlist/invitationlist.component';
import { DetailedAnswersComponent } from './dashboard/resultlist/detailed-answers/detailed-answers.component';
import { ResultReportComponent } from './dashboard/resultlist/result-report/result-report.component';
import { ResultlistComponent } from './dashboard/resultlist/resultlist.component';
import { FilterPipe } from './dashboard/testlist/search.filter.pipe';
import { SendInvitationDialogComponent } from './dashboard/testlist/send-invitation-dialog/send-invitation-dialog.component';
import { TestlistComponent } from './dashboard/testlist/testlist.component';
import { ClientInfoComponent } from './home/client-info/client-info.component';
import { CancelConfirmationDialogComponent } from './home/client-subscriptions/cancel-confirmation-dialog/cancel-confirmation-dialog.component';
import { ClientSubscriptionsComponent } from './home/client-subscriptions/client-subscriptions.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './home/login/login.component';
import { PasswordResetCompleteComponent } from './home/login/password-reset-complete/password-reset-complete.component';
import { PasswordResetComponent } from './home/login/password-reset/password-reset.component';
import { PlanSelectionComponent } from './home/plan-selection/plan-selection.component';
import { SubscribeComponent } from './home/subscribe/subscribe.component';
import { SubscriptionConfirmationComponent } from './home/subscription-confirmation/subscription-confirmation.component';
import { InvitationComponent } from './invitation/invitation.component';
import { ClosingComponent } from './testroom/closing/closing.component';
import { RoomComponent } from './testroom/room.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { StepComponent } from './testroom/step/step.component';
import {MatPaginatorIntlFr} from './dashboard/MatPaginatorIntlFr';
import { from } from 'rxjs';
import { PaymentComponent } from './home/payment/payment.component';
import {CountriesService} from './api/countries.service';
import {StackChipPipe} from "./dashboard/testlist/stack.chip.pipe";
const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'subscribe', component: SubscribeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/password-reset', component: PasswordResetComponent },
  { path: 'login/password-reset-complete', component: PasswordResetCompleteComponent },
  { path: 'plans', component: PlanSelectionComponent },
  { path: 'testroom/closed', component: ClosingComponent },
  { path: 'testroom/:id', component: RoomComponent },
  { path: 'testroom/:id?token=:token', component: RoomComponent },
  {
    path: 'dashboard',
    component: BoardComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'CLIENT'
    },
    children: [
      {
        path: 'invitationlist',
        component: InvitationlistComponent
      },
      {
        path: 'testlist',
        component: TestlistComponent
      },
      {
        path: 'resultlist',
        component: ResultlistComponent
      }, {
        path: 'resultreport/:passageId',
        component: ResultReportComponent
      }, {
        path: 'detailed-answers/:passageId',
        component: DetailedAnswersComponent
      }, {
        path: 'candidatlist',
        component: CandidatlistComponent
      },
      {
        path: 'plans',
        component: PlanSelectionComponent
      },
      {
        path: 'payment',
        component: PaymentComponent
      },
      {
        path: 'client-info',
        component: ClientInfoComponent
      },
      {
        path: 'confirmation',
        component: SubscriptionConfirmationComponent
      }, {
        path: 'my-subscriptions',
        component: ClientSubscriptionsComponent
      }, {
        path: 'faq',
        component: FrequentlyAskedQuestionsComponent
      }
    ]
  },
  { path: 'invitation/:token', component: InvitationComponent }

];

@NgModule({
    declarations: [
        AppComponent,
        RoomComponent,
        BoardComponent,
        AddCandidatDialogComponent,
        SendInvitationDialogComponent,
        InvitationComponent,
        InvitationlistComponent,
        TestlistComponent,
        CandidatlistComponent,
        ResultlistComponent,
        ResultReportComponent,
        ClosingComponent,
        HeaderComponent,
        FilterPipe,
        HomeComponent,
        LoginComponent,
        SubscribeComponent,
        PlanSelectionComponent,
        SubscriptionConfirmationComponent,
        PasswordResetComponent,
        PasswordResetCompleteComponent,
        DetailedAnswersComponent,
        ClientInfoComponent,
        ClientSubscriptionsComponent,
        CancelConfirmationDialogComponent,
        FrequentlyAskedQuestionsComponent,
        ClaimCreationDialogComponent,
        StepComponent,
        PaymentComponent,
        StackChipPipe,
    ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    GaugesModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    BrowserAnimationsModule,
    // HttpModule ,// <=== HERE!
    ChartsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTooltipModule,
    MatListModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
  ],
  providers: [Configuration,
    TestService,
    ClientService,
    PurchaseService,
    UserService,
    InvitationService,
    SearchService,
    AuthenticationService,
    RoleGuard,
    SubscriptionService,
    SubscriptionWorkflowService,
    PlanService,
    ClaimService,
    CountriesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr},
    PermissionService,
    PassageService
  ],
  entryComponents: [ClaimCreationDialogComponent, CancelConfirmationDialogComponent, SendInvitationDialogComponent, AddCandidatDialogComponent, ResultReportComponent],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

     <div id="bodyid" class="demo-blog demo-blog--blogpost mdl-layout mdl-js-layout has-drawer is-upgraded">
      <div class="mdl-layout__content">
       <div class="demo-blog__posts mdl-grid">
          <div class="shadowed mdl-card mdl-shadow--4dp mdl-cell mdl-cell--12-col">
                 <div class="whitefont"> 
                  <p>
                    Vos réponses sont enregistrées. Merci pour votre collaboration.
                  </p>
                 </div>
          </div>
        </div>
      </div>
    </div>

  
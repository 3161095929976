<div class="fab"  >
  <form class='cntt-wrapper'>
    <div id="fab-hdr">
      <h3>Envoyer une invitation</h3>
    </div>

    <div class="cntt">
      <mat-table #table [dataSource]="dataSource">

        <ng-container matColumnDef="checked">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let candidatElement;  let i = index">
           <!-- <label class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect mdl-data-table__select" for="row[i]">
                <input type="checkbox" id="row[i]" class="mdl-checkbox__input"  name="row[i]"   [(ngModel)]="candidatElement.checked"/>
              </label> -->
            <mat-checkbox  [(ngModel)]="candidatElement.checked"
                           [checked]="candidatElement.checked"
                           name="checked"></mat-checkbox>
          </mat-cell>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
          <mat-cell *matCellDef="let candidatElement"> {{candidatElement.candidat.email}} </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="lastname">
          <mat-header-cell *matHeaderCellDef> Nom </mat-header-cell>
          <mat-cell *matCellDef="let candidatElement"> {{candidatElement.candidat.lastname}} </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

      <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
      </mat-paginator>
    </div>

    <div class="btn-wrapper">
      <button class="mdl-button mdl-js-button" [mat-dialog-close]="true" id="cancel">Annuler</button>
      <button class="mdl-button mdl-js-button mdl-button--primary" (click)="sendInvitations()"  id="submit" [disabled]="noneIsSelected()" >Envoyer</button>
    </div>

  </form>
</div>

import {Component, OnInit, Input, NgZone} from '@angular/core';
import {Subscription} from  'app/api/api.model';
import { SubscriptionWorkflowService, Prospect } from 'app/api/subscription.workflow.service';
import {Router} from '@angular/router';
import {SubscriptionService} from '../../api/subscription.service';

@Component({
  selector: 'app-subscription-confirmation',
  templateUrl: './subscription-confirmation.component.html',
  styleUrls: ['./subscription-confirmation.component.css']
})
export class SubscriptionConfirmationComponent implements OnInit {


 public finalSubsciption: Subscription = new Subscription();

  constructor(private subscriptionService: SubscriptionService,
              public router: Router,
              private _ngZone: NgZone) { }



  ngOnInit() {
    this.subscriptionService
      .getSubscriptionsByClientId()
      .subscribe(subscription => {
          this.finalSubsciption = subscription ;
          });

  }

  goToCatalogue() {
    this._ngZone.run(() => {
      this.router.navigate(['/dashboard/testlist']);
    });
  }

}

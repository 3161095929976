/**
 * Created by walid on 10/07/17.
 */

export class Proposition {
  propositionID: string;
  proposition: string;

};

export class Question {
  questionID: string;
  sequenceID: number;
  question: string;
  code: string;
  codeLanguage: string;
  multiple: boolean;
  propositions: Proposition[];

};

export class Test {
  testId: string;
  thema: string;
  level : Level;
  stack : string;
  questions: Question[];
};

export class TestResponse {
  reponseMap: Map<number, QuestionResponse>;
  // reponseList : QuestionResponse[];
}

export class QuestionResponse {
  questionID: string;
  sequenceID: number;
  selectedPropositionID: string;
  propositions: Set<String>;
}

export class Passage {
  id : string;
	datePassage : Date ;
	score: number ;
	totalPoints: number ;
	test: TestSummary;
}

export enum Level {
    BEGINNER,
    INTERMEDIATE,
    ADVANCED
  }


export class TestSummary {
testId : string;
thema : string ;
length : number;
level : Level;
stack : string;
}


export class User {
 id: number;
 email: string;
 firstname: string;
 lastname: string;
 creationDate: Date;
}

export class Client {
  activated: boolean;
  company: string;
  companyType: string;
  country: string;
  email: string;
  id: number;
  lastName: string;
  name: string;
  optin: boolean;
  password: string;
  phoneNumber: string;
  tosAccepted: boolean;
}


export class Invitation {
testId: string;
userId: number;
userEmail: String;
consumed: boolean;
expired: boolean;
token : String;
}

export class InvitationDetailed {
email: String;
score: number;
totalPoints: number;
lastname: String;
datePassage: Date;
consumed: boolean;
passageId: number;
token: String;
testThema: string;
}


export class ScorePerCategory {
  category: String;
  score: Number;
  weight: Number;
}

export class PassageDetailed {
  id: Number;
	datePassage: Date;
	score: number;
	totalPoints;
	test: TestSummary;
	scorePerCategoryList: ScorePerCategory[];
  responseDetails: ResponseDetails[];
  candidat: User;
  percentile: number;
}

export class ResponseDetails {
  question: string;
  selectedPropositions: SelectedProposition[];
  correctPropositions: string[];
}

export class SelectedProposition {
 text: string;
 correct: boolean;
}

export class Plan {
	 id: number;
	 name: string ;
	 price: number;
   monthlyEquivalentPrice: number;
	 description: string;
   stripeId: any;
	}

  export enum  Periodicity{
    MONTHLY ,
    YEARLY ,
    NONE
  }

  export class Subscription{
    id: number;
    plan: Plan;
    purchase : Purchase;
    subscriptionDate: Date;
    endDate: Date;
    periodicity: Periodicity;
  }

  export class Invoice {
      number: string;
      date: string;
      tva: string;
      tvaAmount: string;
      amount: string;
      price: string;
      currency: string;
      city: string;
      country: string;
      line1: string;
      line2: string;
      postal_code: string;
      state: string;
      email: string;
      name: string;
      receipt_number: string;
      receipt_url: string;
  }

export class InvoiceLink {
  invoiceLink: string
}

export class Purchase {
  id: number;
  amount: number;
  externalId: string;
  purchaseDate: Date;
  status: string;
}

export class Claim {
  subject: string;
  body: string;
  status: string;
}

export class Jurisdiction {
  open: boolean;
  unlimited: boolean;
  invitationNumber: number;
}

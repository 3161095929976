<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b>Quelle est la politique de remboursement?</b>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p> Vous avez le droit de vous rétracter jusqu'à 14 jours de la date de souscription.
    Il suffit de suspendre la souscription en cours dans la section mes souscriptions. Ensuite
    Envoyer un email avec le sujet "demande de remboursement".<br>
      Votre demande sera traitée dans un délais de 14 jours de la date de reception de l'email.
    Si vous remplissez les conditions, le remboursement sera effectuée et un email de confirmation vous sera envoyée.
    </p>
    <mat-action-row *ngIf="showActions" >
      <button mat-button color="warn" (click)="openDialog()" >DEMANDER UN REMBOURSEMENT ?</button>
    </mat-action-row>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b>Mon candidat n'a pas reçu l'email de l'invitation. Que faire ?</b>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p> Dans la section invitations. En cliquant sur le bouton 'lien' vous copier tout de suite un lien
    de l'invitation que vous pouvez le lui renvoyer par email.</p>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b>Comment je peux récupérer une facture.</b>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p> Dans la section Mes souscriptions vous pouvez retrouverez une facture qui correspond à votre dernière souscription.
    Si vous êtes prélevé mensuellement, la dernière facture est générée automatiquement chaque mois. <br>
    Si vous avez des modifications à apporter à la facture, merci de vous rendre à la section de reclamation et de faire parvenir les informations que vous voulez avoir sur
      votre facture en remplissant le formulaire 'Demande de facture'.</p>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b>Autres questions et demandes</b>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p> Vous pouvez nous addresser une messsage en cliquant sur le bouton ci dessous.
      Nous ferons de notre mieux pour vous répondre dans les meilleurs délais</p>
    <mat-action-row>
      <button mat-button color="warn" (click)="openDialog()" >ENVOYER UNE REQUÊTE</button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>

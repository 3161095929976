import {Component, Inject, OnInit} from '@angular/core';
import { SubscriptionService} from '../../api/subscription.service';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import {Invoice, InvoiceLink, Periodicity, Subscription} from '../../api/api.model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CancelConfirmationDialogComponent} from "./cancel-confirmation-dialog/cancel-confirmation-dialog.component";




@Component({
  selector: 'app-client-subscriptions',
  templateUrl: './client-subscriptions.component.html',
  styleUrls: ['./client-subscriptions.component.css']
})
export class ClientSubscriptionsComponent implements OnInit {
  public subscription: Subscription;
  panelOpenState = false;
  invoiceReady: boolean;
  invoiceLinkReady: boolean;
  planName: string;
  status: string;
  startDate: Date;
  endDate: Date;
  lastPaymentDate: Date;
  lastPaymentAmount: number;
  currency: string;

  planPrice: number;
  planCurrency: string;
  planTaxPercentage: number;

  name: string;
  lastName: string;
  address_line_1: string;
  address_line_2: string;
  admin_area_2: string;
  admin_area_1: string;
  postal_code: string;
  country_code: string;
  email_address: string;
  downloadLink : string;
  alreadyCancelled: boolean;
  invoice: Invoice;
  invoiceLink: InvoiceLink;

  constructor( public dialog: MatDialog, private subscriptionService: SubscriptionService) { }

  ngOnInit() {
    this.subscriptionService
        .getSubscriptionsByClientId()
        .subscribe( subscription => {
          if (subscription) {
            this.subscription = subscription;
            this.planName = subscription.plan.name;
            this.startDate = subscription.subscriptionDate;
            this.endDate = subscription.endDate;
          }
          /*this.subscriptionService.getInvoice(subscription.id).subscribe(
            invoice => {
              this.invoice = invoice;
              this.invoiceReady = true;
            }
          ); */
          this.subscriptionService.getInvoiceLink(subscription.id).subscribe(
            invoice => {
              this.invoiceLink = invoice;
              this.invoiceLinkReady = true;
            }
          );
        });
  }


  public isTrialSubscription(): boolean {
    return this.subscription.purchase === null ;
  }

  public isCancellable(): boolean {
      return this.subscription.purchase
        && this.subscription.purchase.status !== 'cancelled'
        && !this.alreadyCancelled ;
  }

  public triggerCancelSubscription() {
    this.openDialog();
  }

  public cancelSubscription() {
    if (this.subscription.purchase.externalId && this.subscription.id) {
          this.subscriptionService
            .cancelSubscriptionById(this.subscription.id)
            .subscribe(cancelled => this.alreadyCancelled = true);
    }
  }




  openDialog(): void {
    const dialogRef = this.dialog.open(CancelConfirmationDialogComponent, {
      height: '300px',
      width: '600px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.cancelSubscription();
      }
    });
  }


  printInvoice() {
    const doc = new jsPDF('p', 'mm');

    html2canvas(document.getElementById('last-payment'))
      .then(canvas => {
        const context = canvas.getContext('2d');
        context.scale(2, 2);
        context['dpi'] = 144;
        context['imageSmoothingEnabled'] = false;
        context['mozImageSmoothingEnabled'] = false;
        context['oImageSmoothingEnabled'] = false;
        context['webkitImageSmoothingEnabled'] = false;
        context['msImageSmoothingEnabled'] = false;

        const imgData = canvas.toDataURL('image/png');
        doc.addImage(imgData, 'PNG', 30, 30, 262.4,110.5);
        doc.save('facture_skilloMetrics.pdf');
      });

  }

}

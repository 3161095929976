import {Component, OnInit, AfterViewInit} from '@angular/core';
import {PassageDetailed, TestSummary} from '../../../api/api.model';
import {PassageService} from '../../../api/passage.service.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
import {ChartType, ChartOptions, RadialChartOptions} from 'chart.js';
import {SingleDataSet, Label} from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {ActivatedRoute, Router} from '@angular/router';
import 'chartjs-plugin-labels';
import {PermissionService} from '../../../api/permission.service';

@Component({
  selector: 'app-result-report',
  templateUrl: './result-report.component.html',
  styleUrls: ['./result-report.component.css']
})
export class ResultReportComponent implements OnInit, AfterViewInit {
  public testInfo: TestSummary = new TestSummary();
  public passageInfo: PassageDetailed = new PassageDetailed();
  isMobile: boolean;
  labels = [];
  scores = [];
  selectedName: string;
  overallScore: number;
  totalPoints: number;
  chartReady: boolean;
  percentile: number;
  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    scale: {
      ticks: {
        beginAtZero: true,
        min: 0,
        max: 100,
        stepSize: 10
      }
    }
  };

  radarChartData = [];
  radarChartLabels = [];

  public radarChartType: ChartType = 'radar';

  public pieChartOptions: ChartOptions = {};
  public newOptions = {
    plugins: {
      datalabels: {
        font: {
          size: 0
        }
      },
      labels: {
        render: 'percentage',
        precision: 0,
        fontSize: 12,

        // font color, can be color array for each data or function for dynamic color, default is defaultFontColor
        fontColor: '#000000',
        fontStyle: 'normal',
        // font family, default is defaultFontFamily
        fontFamily: '\'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif',
        // draw text shadows under labels, default is false
        textShadow: true,
        // text shadow intensity, default is 6
        shadowBlur: 10,
        // text shadow X offset, default is 3
        shadowOffsetX: -5,
        // text shadow Y offset, default is 3
        shadowOffsetY: 5,
        // text shadow color, default is 'rgba(0,0,0,0.3)'
        shadowColor: 'rgba(255,0,0,0.75)',
      }
    }
  };
  public pieChartLabels: Label[] = ['Bonnes réponses', 'Mauvaises réponses'];
  public pieChartData: SingleDataSet = [];
  public pieChartColors: Array<any> = [
    {
      backgroundColor: ['#6FC8CE', '#FF7360']
    }];
  public pieChartType: ChartType = 'doughnut';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public limited: boolean;

  constructor(private passageService: PassageService,
              private activatedRoute: ActivatedRoute,
              private permissionService: PermissionService,
              public snackBar: MatSnackBar,
              private  router: Router) {

  }

  onChartClick() {
  }

  ngOnInit() {
    this.permissionService.getPermissions().subscribe(juridiction => {
      this.limited = !juridiction.unlimited;
      if (this.limited) {
        this.openSnackBar();
      }
    });
    const passageId = this.activatedRoute.snapshot.params['passageId'];
    this.labels = [];
    this.scores = [];
    this.passageService.getById(passageId).subscribe(t => {
      this.passageInfo = t;
      t.scorePerCategoryList.forEach(x => {
        this.scores.push(Math.round(+x.score / +x.weight * 100));
        this.labels.push(x.category);
      });
      this.testInfo = t.test;
      this.overallScore = t.score;
      this.totalPoints = t.totalPoints;
      this.selectedName = this.passageInfo.candidat.firstname + ' ' + this.passageInfo.candidat.lastname;
      // -------- charts ------
      this.radarChartData = [];
      this.radarChartData.push({
        label: this.selectedName,
        backgroundColor: 'rgba(163,99,200,0.2)',
        fill: true,
        borderColor: 'rgba(255,99,132,1)',
        pointBorderColor: '#fff',
        pointBackgroundColor: 'rgba(255,99,132,1)',
        data: this.scores
      });
      this.radarChartLabels = [];
      this.radarChartLabels = this.labels;
      this.pieChartData = [
        this.fixPrecision(this.overallScore / this.totalPoints * 100),
        this.fixPrecision((this.totalPoints - this.overallScore) / this.totalPoints * 100)
      ];
      this.percentile = t.percentile;
      this.chartReady = true;


    });
    window.onresize = () => this.isMobile = window.innerWidth <= 700;


  }

  ngAfterViewInit() {
    this.pieChartOptions = this.newOptions;
  }

  print() {

    const doc = new jsPDF('p', 'mm');
    doc.setFont('times', 'bold', 22);
    doc.text(this.passageInfo.candidat.firstname + ' '
      + this.passageInfo.candidat.lastname, 60, 15);

    doc.setFont('times', 'normal', 10);
    doc.setFontSize(8);
    doc.text('Test: ' + this.testInfo.thema, 10, 30);
    doc.text('Niveau: ' + this.testInfo.level, 40, 30);
    doc.text('Nbr de questions: ' + this.testInfo.length, 70, 30);
    doc.text('Date de passage: ' + this.passageInfo.datePassage, 100, 30);
    doc.text('Stack Technique: ' + this.testInfo.stack, 150, 30);
    doc.line(10, 35, 200, 35); // horizontal line


    html2canvas(document.getElementById('image2w'))
      .then(piechart => {
        const piechart_context = piechart.getContext('2d');
        piechart_context.scale(2, 2);
        piechart_context['dpi'] = 144;
        piechart_context['imageSmoothingEnabled'] = false;
        piechart_context['mozImageSmoothingEnabled'] = false;
        piechart_context['oImageSmoothingEnabled'] = false;
        piechart_context['webkitImageSmoothingEnabled'] = false;
        piechart_context['msImageSmoothingEnabled'] = false;

        const imgPieChart = piechart.toDataURL('image/png');
        doc.addImage(imgPieChart, 'PNG', 30, 50, 119.06, 59.53);

        html2canvas(document.getElementById('image1'))
          .then(radarChart => {
            const radarChart_context = radarChart.getContext('2d');
            radarChart_context.scale(2, 2);
            radarChart_context['dpi'] = 144;
            radarChart_context['imageSmoothingEnabled'] = false;
            radarChart_context['mozImageSmoothingEnabled'] = false;
            radarChart_context['oImageSmoothingEnabled'] = false;
            radarChart_context['webkitImageSmoothingEnabled'] = false;
            radarChart_context['msImageSmoothingEnabled'] = false;

            const imgRadarChart = radarChart.toDataURL('image/png');
            doc.addImage(imgRadarChart, 'PNG', 10, 125, 158.75, 79.375);

            html2canvas(document.getElementById('gauge-a'))
              .then(percentileGauge => {
                const percentileGauge_context = percentileGauge.getContext('2d');
                percentileGauge_context.scale(2, 2);
                percentileGauge_context['dpi'] = 144;
                percentileGauge_context['imageSmoothingEnabled'] = false;
                percentileGauge_context['mozImageSmoothingEnabled'] = false;
                percentileGauge_context['oImageSmoothingEnabled'] = false;
                percentileGauge_context['webkitImageSmoothingEnabled'] = false;
                percentileGauge_context['msImageSmoothingEnabled'] = false;

                const imgPercentileGauge = percentileGauge.toDataURL('image/png');
                doc.addImage(imgPercentileGauge, 'PNG', 50, 210, 79.375, 79.375);


                doc.save('result-report.pdf');
              });
          });

      });


  }

  private fixPrecision(val) {
    return Math.floor(val);
  }

  openSnackBar() {
    const snackBarRef = this.snackBar
      .open('Afin d\'enlever la demo watermark et activer plus de graphes, Merci de upgrader votre souscription.',
        'Upgrade', {
          duration: 10000
        });

    snackBarRef.onAction().subscribe(() => {
      this.router.navigateByUrl('/dashboard/plans');
    });
  }


}

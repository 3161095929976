import {AfterViewChecked, AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PassageService} from '../../../api/passage.service.component';
import {ResponseDetails} from '../../../api/api.model';
import {PermissionService} from '../../../api/permission.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
@Component({
  selector: 'app-detailed-answers',
  templateUrl: './detailed-answers.component.html',
  styleUrls: ['./detailed-answers.component.css']
})
export class DetailedAnswersComponent implements OnInit {

  public responseDetails: ResponseDetails[];
  public upgradeNeeded: boolean;


  constructor(
              private passageService: PassageService,
              private activatedRoute: ActivatedRoute,
              private permissionService: PermissionService,
              public snackBar: MatSnackBar,
              private router: Router) {

  }

  ngOnInit() {
    const passageId = this.activatedRoute.snapshot.params['passageId'];
    this.passageService.getById(passageId).subscribe( t => {
      this.responseDetails = t.responseDetails;
    });
    this.permissionService.getPermissions().subscribe(
      jurisdiction => {
        this.upgradeNeeded = !jurisdiction.unlimited;
        if (this.upgradeNeeded) {
          this.openSnackBar('');
        }
      });
  }

  openSnackBar(message: string) {
    const snackBarRef = this.snackBar
      .open('Pour visualiser les réponses de vos candidats, Merci de upgrader votre souscription',
      'Upgrade', { duration : 10000
    });

    snackBarRef.onAction().subscribe(() => {
      this.router.navigateByUrl('/dashboard/plans');
    });
  }





}

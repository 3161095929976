<div *ngIf="isMobile" >
  <section class="container">
    <div class="wave"></div>
  </section>
  <div class="column" id="main">
    <div class="wrapper">
      <div style="display: inline" data-testid="svgRoot-comp-kybbhi6c" class="logo TcoJIb comp-kybbhi6c"><!--?xml version="1.0" encoding="UTF-8"?-->
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="23.5 23.5 153 153" viewBox="23.5 23.5 153 153" height="40" width="40" xmlns="http://www.w3.org/2000/svg" data-type="color" role="img" aria-label="Page d'accueil"><defs><style>#comp-kybbhi6c svg [data-color="1"] {fill: #000000;}</style></defs>
          <g>
            <path d="M158.026 23.5H41.974C31.771 23.5 23.5 31.771 23.5 41.974v116.052c0 10.203 8.271 18.474 18.474 18.474h116.052c10.203 0 18.474-8.271 18.474-18.474V41.974c0-10.203-8.271-18.474-18.474-18.474zM62.37 125.347c-8.382 1.206-16.154-4.611-17.36-12.992s4.611-16.154 12.992-17.36c8.382-1.206 16.154 4.611 17.36 12.992s-4.61 16.154-12.992 17.36zm89.787-43.89-47.193 63.061a14.978 14.978 0 0 1-12.021 6.014c-3.127 0-6.279-.974-8.976-2.992-6.633-4.964-7.985-14.364-3.022-20.997l47.193-63.061c4.964-6.633 14.363-7.985 20.997-3.022 6.632 4.964 7.985 14.365 3.022 20.997z" fill="#111010" data-color="1"></path>
          </g>
        </svg>
      </div>
      <div style="display: inline">
        <p class="title-logo">SkilloMetrics</p>
        <p class="slogan-logo">Dénicher les pépites</p>
      </div>
    </div>
    <p>Nous sommes ravis de vous revoir</p>
    <form name="form" [formGroup]="loginForm" (ngSubmit)="loginForm.valid && login()" class="example-form" novalidate>
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Email" [formControl]="emailFormControl" [errorStateMatcher]="matcher"
               [(ngModel)]="model.username"
               name="username">
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          Veuillez saisir une adresse email valide SVP.
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          Le champ Email est <strong>obligatoire</strong>.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input matInput placeholder="Mot de passe" [type]="hide ? 'password' : 'text'" [(ngModel)]="model.password"
               name="password" [formControl]="passwordFormControl">
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
      </mat-form-field>
      <mat-error *ngIf="unrecognized">
        Login et mot de passe non reconnus.
      </mat-error>
      <mat-card-actions>
        <button type="submit"  class="btn flat-yellow">
          Se connecter
        </button>
        <div class="horizontal-line"></div>
        <div class="subscribe">
          <button class="btn flat-grey"  routerLink="/subscribe">
            Souscrire
          </button>
          <button class="btn flat-grey"  routerLink="/login/password-reset">
            Mot de passe oublié ?
          </button>
        </div>
      </mat-card-actions>
    </form>
  </div>
  </div>



<div *ngIf="!isMobile" class="viewport">
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="column" id="main">
          <h1>Connexion</h1>
          <h3>Nous sommes ravis de vous revoir</h3>
          <form name="form" [formGroup]="loginForm" (ngSubmit)="loginForm.valid && login()" class="example-form" novalidate>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Email" [formControl]="emailFormControl" [errorStateMatcher]="matcher"
                     [(ngModel)]="model.username"
                     name="username">
              <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                Veuillez saisir une adresse email valide SVP.
              </mat-error>
              <mat-error *ngIf="emailFormControl.hasError('required')">
                Le champ Email est <strong>obligatoire</strong>.
              </mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width">
              <input matInput placeholder="Mot de passe" [type]="hide ? 'password' : 'text'" [(ngModel)]="model.password"
                     name="password" [formControl]="passwordFormControl">
              <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
            </mat-form-field>
            <mat-error *ngIf="unrecognized">
              Login et mot de passe non reconnus.
            </mat-error>
            <mat-card-actions>
              <button type="submit"  class="btn btn-primary">
                Se connecter
              </button>

              <button  class="btn btn-secondary" routerLink="/login/password-reset">
                Mot de passe oublié ?
              </button>
            </mat-card-actions>
          </form>
        </div>
        <div>
          <svg width="67px" height="578px" viewBox="0 0 67 578" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Path</title>
            <desc>Created with Sketch.</desc>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path d="M11.3847656,-5.68434189e-14 C-7.44726562,36.7213542 5.14322917,126.757812 49.15625,270.109375 C70.9827986,341.199016 54.8877465,443.829224 0.87109375,578 L67,578 L67,-5.68434189e-14 L11.3847656,-5.68434189e-14 Z" id="Path" fill="#F9BC35"></path>
            </g>
          </svg>
        </div>
        <div class="column" id="secondary">
          <div class="sec-content">
            <h2>Pas de compte ?</h2>
            <h3>Cliquez ici pour souscrire</h3>
            <button type="button" class="btn btn-primary" routerLink="/subscribe">Souscrire</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

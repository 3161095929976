
import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { AuthenticationService } from './authentication.service';
import  jwtDecode from "jwt-decode";

@Injectable()
export class RoleGuardService implements CanActivate {

  constructor(public auth: AuthenticationService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;

    const token = localStorage.getItem('token');

    // decode the token to get its payload
    if (!token) {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
    const tokenPayload : any= jwtDecode(token);

    if (!this.auth.isAuthenticated() || tokenPayload.ROLE !== expectedRole) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

}

/**
 * Created by walid on 10/07/17.
 */
import {Injectable} from '@angular/core';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Invitation, User} from './api.model';
import {Configuration} from './api.configuration.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class UserService {

  private actionUrl: string;
  private headers;

  constructor(private httpClient: HttpClient, private conf: Configuration) {
    this.actionUrl = conf.ServerWithApiUrl + 'users/';
    const _headers = new HttpHeaders();
    this.headers = _headers.append('Content-Type', 'application/json');
  }

  public getAll = () : Observable<User[]> => {

    return this.httpClient.get<User[]>(this.actionUrl)
      // .map((response : Response) => <User[]>response.json())
      ;
  }

  public getAllMyCandidats = () : Observable<User[]> => {

    return this.httpClient.get<User[]>(this.actionUrl )
      // .map((response : Response) => <User[]>response.json())
      ;
  }


  public getById = (id: number): Observable<User> => {
    return this.httpClient.get<User>(this.actionUrl + id)
      // .map((resp : Response) => <User> resp.json() )
      ;
  }


  public createUser =  ( user : User) : Observable<User> => {
   const url : string = this.actionUrl;
    return this.httpClient.post<User>(url, user)
    //  .map( (resp : Response) => <User> resp.json() )
     ;
  }

  public createUserWithClientId =  ( user : User) : Observable<User> => {
   const url : string = this.actionUrl;
    return this.httpClient.post<User>(url, user)
    //  .map( (resp : Response) => <User> resp.json() )
     ;
  }

  public updateUserWithClientId =  ( user: User) : Observable<User> => {
    const url : string = this.actionUrl + user.id;
    return this.httpClient.put<User>(url, user)
    //  .map( (resp : Response) => <User> resp.json() )
      ;
  }

  public inviteUser = (testId: number, userId: number) : Observable<Invitation> => {
    const url: string = this.actionUrl + userId + '/invite?testId=' + testId;
    return this.httpClient.post<Invitation>(url, null);

  }



  public deleteCandidat = (userId: number) : Observable<any> => {
    const url: string = this.actionUrl + userId;
    return this.httpClient.delete(url);
  }




}

<div [ngClass]="question.code? 'room-coded-card-wide' : 'room-card-wide'" class="mdl-grid mdl-card mdl-shadow--6dp">
    <div [ngClass]="question.code? 'mdl-coded-card__title' : 'mdl-card__title'">
        <h2 class="mdl-card__title-text"><b>{{question.question}}</b></h2>
        <div *ngIf="question.code" class="code-container">
            <pre id="code-pre-cont" class="prettyprint linenums lang-{{question.codeLanguage}}">{{question.code}}</pre>
        </div>
    </div>
    <ul class="demo-list-control mdl-list">
        <li class="mdl-list__item" *ngFor="let proposition of question.propositions">
            <span class="mdl-list__item-secondary-action" style="white-space: pre-line;">
                <label *ngIf="!question.multiple" style="white-space: pre-line;"
                    class="demo-list-radio mdl-radio mdl-js-radio mdl-js-ripple-effect"
                    for="id-{{proposition.propositionID}}">
                    <mat-radio-button  class="my-alternate-theme" color="accent" type="radio"
                        id="id-{{proposition.propositionID}}" class="mdl-radio__button"
                        name="options-{{question.questionID}}" value="proposition.propositionID"
                        [checked]="getValue(question, proposition.propositionID)"
                        (change)="onSelectionChange(question, proposition.propositionID)"
                        style="white-space: pre-line;">
                        {{proposition.proposition}}
                    </mat-radio-button>
                </label>
                <mat-checkbox #checkboxMultiple
                              *ngIf="question.multiple" class="my-alternate-theme" color="accent"
                            class="mdl-radio__button"
                            value="proposition.propositionID"
                            [checked]="getValue(question, proposition.propositionID)"
                            (change)="onSelectionCheckBoxChange($event, question, proposition.propositionID)"
                            style="white-space: pre-line !important;">
                        {{proposition.proposition}}
                </mat-checkbox>
            </span>
            <span class="mdl-list__item-text-body">
                <!--<label for="id-{{proposition.propositionID}}">{{proposition.proposition}}</label>
                      --></span>
        </li>
    </ul>
</div>

/**
 * Created by walid on 28/05/18.
 */
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {Client } from './api.model';
import { Configuration } from './api.configuration.component';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ClientService {

  private actionUrl: string;
  private headers: Headers;

  constructor(private httpClient: HttpClient, private conf: Configuration) {
    this.actionUrl = conf.ServerWithApiUrl + 'client';
  }

  public getById(id: number): Observable<Client> {
    return this.httpClient.get<Client>(this.actionUrl + '/' + id);
  }

  public createClient(client: Client ): Observable<Client> {
    return this.httpClient.post<Client>(this.actionUrl, client);
  }

  public updateClient(client: Client, clientId: number): Observable<Client> {
    return this.httpClient.put<Client>(this.actionUrl + '/' + clientId, client);
  }




}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {Claim} from '../../../api/api.model';
import {FormControl, FormGroup} from "@angular/forms";
import { from } from 'rxjs';

@Component({
  selector: 'app-claim-creation-dialog',
  templateUrl: './claim-creation-dialog.component.html',
  styleUrls: ['./claim-creation-dialog.component.css']
})
export class ClaimCreationDialogComponent implements OnInit {

  public claim: Claim = new Claim();
  public claimForm: FormGroup;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ClaimCreationDialogComponent>) {}

  ngOnInit() {

    this.claimForm = new FormGroup({
      subject: new FormControl(this.claim.subject),
      body: new FormControl(this.claim.body),
      status: new FormControl('')
    });
  }



  onNoClick(): void {
    this.dialogRef.close();
  }

}

/**
 * Created by walid on 10/07/17.
 */
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {PassageDetailed} from './api.model';
import { Configuration } from './api.configuration.component';
import { HttpClient } from "@angular/common/http";

@Injectable()
export class PassageService {

  private actionUrl: string;
  private headers: Headers;

  constructor(private httpClient: HttpClient, private conf: Configuration) {

    this.actionUrl = conf.ServerWithApiUrl + 'passage';

    this.headers = new Headers();
    // this.headers.append("Access-Control-Allow-Origin", "*");
    //  this.headers.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
    // this.headers.append('Content-Type', 'application/json');
    // this.headers.append('Accept', 'application/json');
  }


  public getById = (token:number): Observable<PassageDetailed> => {
    return this.httpClient.get<PassageDetailed>(this.actionUrl +"/"+ token)
      // .map((resp : Response) => <PassageDetailed> resp.json() )
      ;
  }



}

import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent {


  constructor(private translate: TranslateService,
              public router: Router) {
    translate.setDefaultLang('fr');
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {ClientService} from '../../api/client.service.component';
import {Configuration} from '../../api/api.configuration.component';
import {Client} from '../../api/api.model';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {take} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css'],
  providers: []
})
export class SubscribeComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject<void>();

  hide = true;
  validForm = false;
  alreadyExists = false;
  public myForm: FormGroup;

  myClient: Client = new Client();

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(2)
  ]);
  matcher = new MyErrorStateMatcher();

  public isMobile: boolean;

  constructor(private clientService: ClientService,
              private formBuilder: FormBuilder,
              public router: Router) {
  }


  ngOnInit() {
    this.isMobile = (window.innerWidth <= 700);

    this.myForm = this.formBuilder.group({
      email: this.emailFormControl,
      password:  this.passwordFormControl,
    });

    this.myForm.statusChanges.subscribe((status) => {
      this.validForm = status === 'VALID';
      });

    this.myForm.valueChanges.subscribe((status) => {
        this.alreadyExists = false;
      });

  }

  onSubmit() {
    if (this.validForm) {
        this.clientService.createClient(this.myClient).pipe(take(1))
          .subscribe({
         next: () => {
            this.router.navigateByUrl('/login');
          },
         error: () =>   (error: HttpErrorResponse) => {
            if (error && error.status === 409) {
              this.alreadyExists = true;
              this.myForm.get('email').setErrors(new Error('exists'));
            }
          }
    } )
        ;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

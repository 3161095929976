<div  *ngFor="let test of list | filter : searchQuery" class="demo-card-wide mdl-card mdl-shadow--2dp">


  <div class="mdl-card__title">
    <label>{{test.thema}}</label>
  </div>
  <div class="mdl-card__supporting-text" style="display: inline-grid;">
    <!-- Contact Chip -->
    <div  style="display: inline;">
      <span class="mdl-chip mdl-color--blue-100 mdl-chip--contact">
        <span class="mdl-chip__text  shift-left">   Nombre de questions </span>
        <span class="mdl-chip__contact mdl-color--indigo-900	 mdl-color-text--white">{{test.length}}</span>
      </span>
    </div>
    <div  style="display: inline;">
      <ng-container  *ngFor="let stack of (test.stack | stackChip)">
      <span class="mdl-chip">
        <span class="mdl-chip__text">{{stack}}</span>
      </span>
      </ng-container>
    </div>
  </div>
  <div class="mdl-card__actions mdl-card--border">
    <button mat-icon-button
            matTooltip="Preview"
            [routerLink]="['/testroom/',test.testId]"
            disabled="{{!subscriptionStatus}}">
          <i class="material-icons">preview</i>
    </button>
    <button mat-icon-button
            matTooltip="Inviter un nouveau candidat"
            (click)="openDialog(test.testId)"
            disabled="{{!subscriptionStatus}}">
           <i class="material-icons">person_add</i>
    </button>
    <button mat-icon-button
            matTooltip="Inviter un candidat existant"
            (click)="sendInvitation(test.testId)"
            disabled="{{!subscriptionStatus}}">
                <i class="material-icons">mail_outline</i>
    </button>
  </div>
  <div class="mdl-card__menu">
      <div
        [matTooltip]=comptueNiveau(test.level)
        [ngClass]="comptueLevel(test.level)">

      </div>
  </div>
</div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthenticationService} from 'app/api/authentication.service';
import {FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Subject} from "rxjs";
import {take} from "rxjs/operators";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],

})
export class LoginComponent implements OnInit , OnDestroy {

  private ngUnsubscribe = new Subject<void>();
  model: any = {username: '', password: ''};
  unrecognized = false;
  returnUrl: string;
  hide = true;
  validForm = false;
  public isMobile: boolean;

  emailFormControl = new FormControl(this.model.username, [
    Validators.required,
    Validators.email,
  ]);

  passwordFormControl = new FormControl(this.model.password, [
    Validators.required,
    Validators.minLength(2)
  ]);

  public loginForm = this.formBuilder.group({
    email: this.emailFormControl,
    password: this.passwordFormControl,
  });

  matcher = new MyErrorStateMatcher();


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder
    /*private alertService: AlertService*/) {
  }

  ngOnInit() {

    this.isMobile = (window.innerWidth <= 700);
    window.onresize = () => this.isMobile = (window.innerWidth <= 700);
    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard/testlist';
    this.loginForm.statusChanges.subscribe((status) => {
      this.unrecognized = false;
      this.validForm = status === 'VALID';
    });
  }

  login() {
    this.authenticationService.login(this.model.username, this.model.password)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.router.navigate([this.returnUrl]);
        },
        error: () => {
          this.unrecognized = true;
        }
  });
  }

  goToResetPwd() {
    this.router.navigateByUrl('login/password-reset');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

/**
 * Created by walid on 28/05/18.
 */
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {Subscription, Client, Plan, Purchase, Invoice, InvoiceLink} from './api.model';
import { Configuration } from './api.configuration.component';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class SubscriptionService {

  private actionUrl: string;
  private headers: Headers;



  constructor(private httpClient: HttpClient, private conf: Configuration,  handler: HttpBackend) {
    this.actionUrl = conf.ServerWithApiUrl + 'subscriptions';
  }

  public getById = (id: number): Observable<Subscription> => {
    return this.httpClient.get<Subscription>(this.actionUrl + '/' + id);
      // .map((resp : Response) => <Client> resp.json() )
  }

  public cancelSubscriptionById = (id: number): Observable<Subscription> => {
    return this.httpClient.delete<Subscription>(this.actionUrl + '/' + id);
  }

  public getInvoice(id: number): Observable<Invoice> {
    return this.httpClient.get<Invoice>(this.actionUrl + '/' + id + '/invoice');
  }

  public getInvoiceLink(id: number): Observable<InvoiceLink> {
    return this.httpClient.get<InvoiceLink>(this.actionUrl + '/' + id + '/invoice-link');
  }

  public getSubscriptionsByClientId = (): Observable<Subscription> => {
    return this.httpClient.get<Subscription>(this.actionUrl);
  }

  public createSubscription =  (): Observable<Subscription> => {
    return this.httpClient.post<Subscription>(this.actionUrl, null)
     ;
  }
  public createPaidSubscription =  ( plan: Plan, purchase: Purchase ) : Observable<Subscription> => {
    const subscription: Subscription = new Subscription();
    subscription.plan = plan;
    subscription.purchase = purchase;
    return this.httpClient.post<Subscription>(this.actionUrl + '/paid', subscription)
     ;
  }

}

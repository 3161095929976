import { Component, OnInit, ViewChild } from '@angular/core';
import {Configuration} from '../../api/api.configuration.component';
import {InvitationService} from '../../api/invitation.service.component';
import {Invitation, InvitationDetailed} from '../../api/api.model';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarModule} from '@angular/material/snack-bar';
import {MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-invitationlist',
  templateUrl: './invitationlist.component.html',
  providers : [Configuration , InvitationService],
  styleUrls: ['./invitationlist.component.css']
})
export class InvitationlistComponent implements OnInit {
  pendingInviteList: InvitationDetailed[];
  displayedColumns = ['email', 'lastname', 'testThema', 'creationDate', 'link'];
  dataSource: MatTableDataSource<InvitationDetailed> = new MatTableDataSource<InvitationDetailed>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isMobile = false;
  constructor(private inviteService: InvitationService,
  public snackBar: MatSnackBar) { }

  ngOnInit() {
   this.inviteService.getDetailedInvitationByClientId()
   .subscribe( t => {
     this.pendingInviteList = t.filter( e => !e.consumed) ;
      this.dataSource = new MatTableDataSource<InvitationDetailed>(this.pendingInviteList);
      this.dataSource.paginator = this.paginator;
     this.dataSource.sort = this.sort;
   });
    this.isMobile = (window.innerWidth <= 700);
    window.onresize = () => this.isMobile = (window.innerWidth <= 700);
  }

  openSnackBar(message: string) {
    const inp = document.createElement('input');
    document.body.appendChild(inp);
    inp.value = location.origin + '/invitation/' + message;
    inp.select();
    document.execCommand('copy', false);
    inp.remove();
    this.snackBar.open('le lien de l\'invitation a été copié', '', {
      duration: 3000
    });
  }


}

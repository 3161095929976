<div style="width:100%;display: inline">

<div style=" display: inline-block; width: 90%;" id="report">
  <div class="report-header" id ="header-rep">
    <div style="font-size: 20px; font-weight: bold; margin-left: 5px;">
      <div id="name-container"><i style="font-size: 32px;" class="material-icons">
        perm_identity
      </i> {{passageInfo?.candidat?.firstname}} {{passageInfo?.candidat?.lastname}} <div style="font-size: 12px; color: #595959; display: inline; font-weight: normal;">({{passageInfo?.candidat?.email}})</div>
      </div>

    </div>
    <div>

    </div>
    <div id="details-container" class="parent">
      <div class="child"> <b>Test</b> : {{testInfo.thema}} </div>
      <div class="child"> <b>Niveau </b>: {{testInfo.level}} </div>
      <div class="child"> <b>Nombre de questions </b>: {{testInfo.length}} </div>
      <div class="child"> <b>Date de passage </b>: {{passageInfo.datePassage}} </div>
      <div class="child"> <b>Stack Technique </b>: {{testInfo.stack}} </div>
    </div>
  </div>
  <div class="first-graphs-container">
    <canvas [ngClass]="{'demo':limited}" *ngIf="chartReady" id ="image2w"
            baseChart
            [data]="pieChartData"
            [colors]="pieChartColors"
            [labels]="pieChartLabels"
            [chartType]="pieChartType"
            [options]="pieChartOptions"
            [legend]="pieChartLegend">
    </canvas>
    <div class="gauge">
      <span>le candidat dépasse {{percentile}} % de la population qui a passé ce test.</span>
      <canvas *ngIf="chartReady"
              id="gauge-a"
              data-type="radial-gauge"
              data-width="300"
              data-height="300"
              data-units="Percentile"
              [attr.data-value]="percentile"
              data-min-value="0"
              data-start-angle="90"
              data-ticks-angle="180"
              data-value-box="false"
              data-max-value="100"
              data-major-ticks="0,20,40,60,80,100"
              data-minor-ticks="2"
              data-stroke-ticks="true"
              data-highlights='[
          {"from": 0, "to": 50, "color": "rgba(200, 50, 50, .75)"},
          {"from": 50, "to": 80, "color": "rgba(220, 200, 0, .75)"},
          {"from": 80, "to": 100, "color": "rgba(100, 255, 100, .2)"}
      ]'
              data-color-plate="#fff"
              data-border-shadow-width="0"
              data-borders="false"
              data-needle-type="arrow"
              data-needle-width="2"
              data-needle-circle-size="7"
              data-needle-circle-outer="true"
              data-needle-circle-inner="false"
              data-animation-duration="800"
              data-animation-rule="linear"
      ></canvas>
    </div>
  </div>

  <div class="parent-graph">

  <canvas  *ngIf="chartReady" [ngClass]="{'demo':limited}" id ="image1"
      baseChart
      [chartType]="radarChartType"
      [datasets]="radarChartData"
      [labels]="radarChartLabels"
      [options]="radarChartOptions"
      [legend]="true"
      (chartClick)="onChartClick($event)">
  </canvas>
  </div>


</div>
</div>
 <div *ngIf=!isMobile class="btn-wrapper">
      <button class="mdl-button mdl-js-button mdl-button--primary" (click)="print()"  id="submit" >Télécharger le rapport</button>
    </div>

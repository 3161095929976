/**
 * Created by walid on 10/07/17.
 */
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {Invitation, InvitationDetailed } from './api.model';
import { Configuration } from './api.configuration.component';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class InvitationService {

  private actionUrl: string;
  private headers: Headers;

  constructor(private httpClient: HttpClient, private conf: Configuration) {

    this.actionUrl = conf.ServerWithApiUrl + 'invitation';

    this.headers = new Headers();
    // this.headers.append("Access-Control-Allow-Origin", "*");
    //  this.headers.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
    // this.headers.append('Content-Type', 'application/json');
    // this.headers.append('Accept', 'application/json');
  }


  public getByToken = (token: string): Observable<Invitation> => {
    return this.httpClient.get<Invitation>(this.actionUrl + '/' + token)
      // .map((resp : Response) => <Invitation> resp.json() )
      ;
  }

 public getAllInvitationByClientId = (clientId : number) : Observable<Invitation[]> => {
   return this.httpClient.get<Invitation[]>(this.actionUrl)
  //  .map((resp : Response) => <Invitation> resp.json())
   ;
 }

 public getDetailedInvitationByClientId = () : Observable<InvitationDetailed[]> => {
   return this.httpClient.get<InvitationDetailed[]>(this.actionUrl + '/detailed');
 }


 public sendInvitationEmail= (clientId : number, token : String) : Observable<String> => {
   return this.httpClient.post<String>(this.actionUrl + '/sendEmail?token=' + token , null)
   ;
 }






}

import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {Configuration} from 'app/api/api.configuration.component';
import {UserService} from 'app/api/user.service.component';
import {InvitationService} from 'app/api/invitation.service.component';
import {TestService} from 'app/api/test.service.component';
import {User} from 'app/api/api.model';
import {TestlistComponent} from 'app/dashboard/testlist/testlist.component';
import {ErrorStateMatcher} from '@angular/material/core';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';

@Component({
     providers : [Configuration , TestService, UserService, InvitationService],
  selector: 'app-add-candidat-dialog',
  templateUrl: './add-candidat-dialog.component.html',
  styleUrls: ['./add-candidat-dialog.component.css']
})
export class AddCandidatDialogComponent implements OnInit, AfterViewInit {

  myUser: User;
  testId: number;
  clientId: number;
  parentComp: TestlistComponent;
  public addCandidatForm: FormGroup;
  validForm= false;
  emailFormControl: FormControl;
  dialogTitle: string;
  matcher = new CandidatErrorStateMatcher();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder,
              private userService: UserService,
              private invitationService: InvitationService) {
    this.myUser = new User();
    this.testId = this.data.testId;
    this.parentComp = this.data.parentComp;
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.clientId = currentUser;
  }

  ngAfterViewInit(): void {
  }

 ngOnInit(): void {
    if (this.data.candidat) {
      this.myUser = this.data.candidat;
    }
   this.emailFormControl = new FormControl(this.myUser.email, [
     Validators.required,
     Validators.email
   ]);
   this.addCandidatForm = new FormGroup({
     email: this.emailFormControl,
     firstname: new FormControl(this.myUser.firstname),
     lastname: new FormControl(this.myUser.lastname)
   });

   this.addCandidatForm.statusChanges.subscribe((status)  =>  {
     this.validForm   = status ===  'VALID';
   });

   if ( this.data.testId) {
     this.dialogTitle = 'Inviter un nouveau Candidat';
   } else if ( this.myUser.id) {
     this.dialogTitle = 'Éditer un Candidat';
   } else {
     this.dialogTitle = 'Créer un nouveau Candidat';
   }
}

  saveUser() {
    if ( this.myUser.id) {
      this.userService.updateUserWithClientId(this.myUser)
        .subscribe( client => this.parentComp.dialog.closeAll());
    }else {
      this.userService.createUserWithClientId(this.myUser).subscribe(t => {
        if (this.testId !== undefined) {
          this.userService.inviteUser(this.testId, t.id).subscribe(i =>
            this.invitationService.sendInvitationEmail(this.clientId, i.token).subscribe());
        }
        this.parentComp.dialog.closeAll();
      });
    }
  }


}

export class CandidatErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

import { Component, OnInit , ViewChild } from '@angular/core';
import {Configuration} from '../../api/api.configuration.component';
import {InvitationService} from '../../api/invitation.service.component';
import {PassageService} from '../../api/passage.service.component';
import {Invitation, InvitationDetailed} from '../../api/api.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort} from '@angular/material/sort';
import { MatTableDataSource} from '@angular/material/table';
import {ResultReportComponent} from './result-report/result-report.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from "@angular/router";


@Component({
  selector: 'app-resultlist',
  templateUrl: './resultlist.component.html',
   providers : [Configuration , InvitationService, PassageService],
  styleUrls: ['./resultlist.component.css']
})

export class ResultlistComponent implements OnInit {
  pendingInviteList: InvitationDetailed[];
  respondedInviteList: InvitationDetailed[];
  labels: String[]= [];
  scores: Number[]= [];
  displayedColumns = ['email', 'lastname', 'testThema', 'score', 'datePassage', 'link'];
  dataSource: MatTableDataSource<InvitationDetailed> = new MatTableDataSource<InvitationDetailed>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isMobile: boolean = false;

  constructor(private inviteService: InvitationService,
    private passageService : PassageService,
      private router: Router,
      public dialog: MatDialog) { }

  ngOnInit() {
   this.inviteService.getDetailedInvitationByClientId()
   .subscribe( t => {
     this.respondedInviteList = t.filter( e => e.consumed) ;
     this.dataSource = new MatTableDataSource<InvitationDetailed>(this.respondedInviteList);
     this.dataSource.paginator = this.paginator;
     this.dataSource.sort = this.sort;
   });
    this.isMobile = (window.innerWidth <= 700);
    window.onresize = () => this.isMobile = (window.innerWidth <= 700);
  }

  resize() {
    this.isMobile = (window.innerWidth <= 700);
}

 openDialog(passageId: number, name: String) {
   this.router.navigateByUrl('dashboard/resultreport/' + passageId);
  }

  openDetailedAnswers(passageId: number, name: String) {
   this.router.navigateByUrl('dashboard/detailed-answers/' + passageId);
  }

}

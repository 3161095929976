import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import { Question, QuestionResponse, TestResponse } from 'app/api/api.model';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css']
})
export class StepComponent implements OnInit {
  @Input()
  public question: Question;

  @Input()
  public  myResponse: TestResponse;

  @ViewChildren
  ('checkboxMultiple') private checkboxesMultiple : QueryList<any>;


  constructor() { }

  ngOnInit(): void {

  }

  onSelectionCheckBoxChange(event, question, propositionID) {


    let qr: QuestionResponse  = this.myResponse.reponseMap.get(question.sequenceID);
    if (qr) {
      qr.selectedPropositionID = propositionID;
      qr.sequenceID = question.sequenceID;
      this.myResponse.reponseMap.set(question.sequenceID, qr);
      if (event.checked) {
        qr.propositions.add(propositionID);
      } else {
        qr.propositions.delete(propositionID);
      }

      this.myResponse.reponseMap.set(question.sequenceID, qr);
    } else {
      qr = new QuestionResponse();
      qr.questionID = question.questionID;
      qr.selectedPropositionID = propositionID;
      qr.sequenceID = question.sequenceID;
      qr.propositions = new Set<String>();
      qr.propositions.add(propositionID);


      this.myResponse.reponseMap.set(question.sequenceID, qr);
    }
  }

  onSelectionChange(question, propositionID) {
    let qr: QuestionResponse  = this.myResponse.reponseMap.get(question.sequenceID);
    if (!qr) {
      qr = new QuestionResponse();
    }
    qr.selectedPropositionID = propositionID;
    qr.sequenceID = question.sequenceID;
    qr.propositions = new Set<String>();
    qr.propositions.add(propositionID);
    this.myResponse.reponseMap.set(question.sequenceID, qr);
  }

   getValue(question, propositionID) {
      const qr: QuestionResponse  = this.myResponse.reponseMap.get(question.sequenceID);
      return qr ?  (qr.propositions.has(propositionID)) : false;
  }

}

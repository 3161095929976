


import { Injectable } from "@angular/core";
import { Configuration } from "app/api/api.configuration.component";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Purchase } from "app/api/api.model";

@Injectable()
export class PurchaseService{

 private actionUrl: string;

constructor(private httpClient: HttpClient, private conf: Configuration) {
    this.actionUrl = conf.ServerWithApiUrl + 'purchases';
  }

 public getById = (id:number): Observable<Purchase> => {
    return this.httpClient.get<Purchase>(this.actionUrl + id)
      ;
  }


  public createPurchase=  ( purchase: Purchase) : Observable<Purchase> => {
   var url: string = this.actionUrl;
    return this.httpClient.post<Purchase>(url, purchase)
     ;
  }



}

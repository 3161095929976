/**
 * Created by walid on 10/07/17.
 */
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {TestSummary, Test , TestResponse, QuestionResponse, Passage } from './api.model';
import { Configuration } from './api.configuration.component';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TestService {

  private actionUrl: string;
  private headers: Headers;

  constructor(private httpClient: HttpClient, private conf: Configuration) {

    this.actionUrl = conf.ServerWithApiUrl + 'tests';

    this.headers = new Headers();
    // this.headers.append("Access-Control-Allow-Origin", "*");
    //  this.headers.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
    // this.headers.append('Content-Type', 'application/json');
    // this.headers.append('Accept', 'application/json');
  }

  public getAll = (): Observable<TestSummary[]> => {
    return this.httpClient.get<TestSummary[]>(this.actionUrl);
  }

  public getById = (id: number): Observable<Test> => {
    return this.httpClient.get<Test>(this.actionUrl + '/' + id)
      ;
  }


  public postReponse =  (id: string, token: string, reponse: TestResponse  ): Observable<Passage> => {
   const url: string = this.actionUrl + '/' + id + '/response?token=' + token;


   const final = new Object();
    for (const k of Array.from(reponse.reponseMap.keys())) {

      const props = [];
      for (const e of Array.from(reponse.reponseMap.get(k).propositions)) {
        props.push(e);
      }
      const entry = {
        questionID: reponse.reponseMap.get(k).questionID,
        sequenceID: reponse.reponseMap.get(k).sequenceID,
        selectedPropositionID: reponse.reponseMap.get(k).selectedPropositionID,
        propositions: props
      }
      final[k] =  entry;
    }
   const val = {
       reponseMap: final
    };

    return this.httpClient.post<Passage>(url, val)
     ;
  }



}

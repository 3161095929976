/**
 */
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {Plan } from './api.model';
import { Configuration } from './api.configuration.component';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PlanService {

  private actionUrl: string;
  private headers: Headers;

  constructor(private httpClient: HttpClient, private conf: Configuration) {
    this.actionUrl = conf.ServerWithApiUrl + 'plans';
  }

 public getAll = () : Observable<Plan[]> => {
    return this.httpClient.get<Plan[]>(this.actionUrl)
      ;
  }

  public getPlansByClientId = (clientId: number, vat: boolean ) : Observable<Plan[]> => {
    return this.httpClient
                .get<Plan[]>(this.actionUrl);
  }

   public getById = (id: number): Observable<Plan> => {
    return this.httpClient.get<Plan>(this.actionUrl + '/' + id)
      ;
  }



}

import { Pipe, PipeTransform, Input } from '@angular/core';
import { TestSummary } from '../../api/api.model'
@Pipe({
    name: 'filter'
})

export class FilterPipe implements PipeTransform {

    transform(items: TestSummary[], query: string): any[] {
        if (!items) return [];
        if (!query) {
            return items;
        }
        query = query.toLowerCase();

        return items.filter(it => {
            return it.stack.toLowerCase().includes(query);
        });
    }
}

<mat-accordion *ngIf="subscription">
  <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b>{{planName}}</b>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p><b>Date de souscription </b>: {{startDate | date: 'dd/MM/yyyy'}}</p>
    <p><b>Date de fin</b> : {{endDate | date: 'dd/MM/yyyy'}}</p>
    <mat-action-row>
      <button *ngIf="!isTrialSubscription() && isCancellable()" mat-button color="warn" (click)="triggerCancelSubscription()">Suspendre</button>
      <button *ngIf="isTrialSubscription()" mat-button color="primary" routerLink="../plans">Upgrade</button>
    </mat-action-row>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="invoice && !isTrialSubscription()">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b>Facture n {{invoice.number}}</b>
      </mat-panel-title>
      <mat-panel-description>
        {{invoice.date | date: 'dd/MM/yyyy'}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngIf="invoiceReady" id="last-payment">
      <div id="client-inf">
        <pre *ngIf="invoice.name">{{invoice.name}}</pre>
        <pre *ngIf="invoice.line1">{{invoice.line1}}</pre>
        <pre *ngIf="invoice.line2">{{invoice.line2}}</pre>
        <pre *ngIf="invoice.city">{{invoice.city}}</pre>
        <pre *ngIf="invoice.state">{{invoice.state}}</pre>
        <pre *ngIf="invoice.postal_code">{{invoice.postal_code}}</pre>
        <pre *ngIf="invoice.country">{{invoice.country}}</pre>
        <pre *ngIf="invoice.email">{{invoice.email}}</pre>
      </div>
      <div id="company-inf">
        <pre>
          SkilloMetrics
          Facture émise par Deblabs
          46 avenue de la République
          Maisons-Alfort 94700
          France
          SIRET : 09479ww707r--w-79327
        </pre>
      </div>
      <div id="amounts-table">
        <p style="font-family: monospace;"><b>Date de paiement</b> : {{invoice.date}}</p>
        <p style="font-family: monospace;"><b>Facture n</b> : {{invoice.number}}</p>
        <table id="inner" cellspacing="0" class="software">
          <tr>
            <td>Souscription</td>
            <td> {{planName}}</td>
          </tr>
          <tr>
            <td>Prix</td>
            <td> {{invoice.price}} {{invoice.currency}}</td>
          </tr>
          <tr>
            <td>Taux TVA</td>
            <td> {{invoice.tva}} %</td>
          </tr>
          <tr>
            <td>Montant TVA</td>
            <td> {{invoice.tvaAmount}} {{invoice.currency}}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td>{{invoice.amount}} {{invoice.currency}}</td>
          </tr>
        </table>
      </div>

    </div>
    <div *ngIf="!invoiceReady">En cours de traitement</div>
    <mat-action-row>
      <button *ngIf="invoiceReady" mat-button color="primary" (click)="printInvoice()">Télécharger La facture</button>
    </mat-action-row>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="invoiceLink && !isTrialSubscription()">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b> Télécharger votre facture</b>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <button *ngIf="invoiceLink" mat-button color="primary" ><a target="_blank" href="{{invoiceLink.invoiceLink}}">Télécharger La facture</a></button>

    </div>
  </mat-expansion-panel>

</mat-accordion>

import { Component, OnInit } from '@angular/core';
import {Form, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../api/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-password-reset-complete',
  templateUrl: './password-reset-complete.component.html',
  styleUrls: ['./password-reset-complete.component.css']
})
export class PasswordResetCompleteComponent implements OnInit {
  model: any = {key: '', password: ''};
  hide = true;
  passwordResetForm: FormGroup;
  public isMobile: boolean;


  constructor(private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private router: Router) {
    this.passwordResetForm = this.formBuilder.group(
      {
        key: new FormControl(this.model.key, [Validators.required]),
        password: new FormControl(this.model.password, [Validators.required])
      }
    );
  }

  ngOnInit() {
    this.isMobile = (window.innerWidth <= 700);
    window.onresize = () => this.isMobile = (window.innerWidth <= 700);

  }

  completePasswordReset() {
    this.authenticationService.finishResetPassword(this.passwordResetForm.get('key').value,
      this.passwordResetForm.get('password').value)
      .subscribe(e => this.router.navigateByUrl('login')
      , error => {
          if (error.status === 404) {
            this.passwordResetForm.controls['key'].setErrors({ 'unrecognized' : true });
          }
        });
  }

}

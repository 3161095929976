import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {ClientService} from '../../api/client.service.component';
import {Prospect, SubscriptionWorkflowService} from '../../api/subscription.workflow.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {CountriesService} from '../../api/countries.service';
import {SubscriptionService} from '../../api/subscription.service';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.css']
})
export class ClientInfoComponent implements OnInit {

  public clientForm: FormGroup;
  public clientId: number;
  public validForm: boolean;
  public prospect: Prospect;
  public yearly: boolean;
  public planId: string;

  filteredOptions: Observable<string[]>;
  companyTypeOptions: Observable<string[]>;
  states;
  companyTypes = [
    {name: 'Auto-Entrepreneur / Micro-Entreprise', vatApplicable: true},
    {name: 'EURL/SARL/SAS/SASU/EI..', vatApplicable: false},
    {name: 'Autres', vatApplicable: true}
  ];
  countryFormControl = new FormControl('', [
    Validators.required,
  ]);

  companyTypeFormControl = new FormControl('', [
    Validators.required,
  ]);
  tosFormControl = new FormControl(false, [
    Validators.required,
  ]);

  constructor(private formbuilder: FormBuilder,
              private clientService: ClientService,
              private workflow: SubscriptionWorkflowService,
              public router: Router,
              private route: ActivatedRoute,
              private countriesService: CountriesService,
              private subscriptionService: SubscriptionService) {
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.planId = params['planId'];
      this.yearly = params['yearly'];
    });

    this.clientForm = this.formbuilder.group({
      companyType: [''],
      lastName: [''],
      name: [''],
      email: [{value: '', disabled: true}],
      company: [''],
      country: this.countryFormControl,
      phoneNumber: [''],
      tosAccepted: [false, Validators.requiredTrue]
    });

    this.workflow.getProspect().subscribe(prospect => {
      this.clientId = prospect.clientId;
      this.prospect = prospect;
      this.clientService.getById(this.clientId).subscribe(
        client => {
          this.clientForm.patchValue({
            lastName: client.lastName,
            name: client.name,
            email: client.email,
            company: client.company,
            companyType: client.companyType,
            country: client.country,
            phoneNumber: client.phoneNumber,
            tosAccepted: client.tosAccepted
          });
        });
    });


    this.filteredOptions = this.clientForm.get('country').valueChanges.pipe(
      startWith(''),
      map(value => {
        return this._filterCountry(value);
      })
    );



    this.clientForm.statusChanges.subscribe((status) => {
      this.validForm = status === 'VALID';
    });


    this.states = this.countriesService.getStates();

  }

  saveClient() {
    this.clientService
      .updateClient(this.clientForm.getRawValue(), this.clientId)
      .subscribe(
        client => {
          this.workflow.setProspect(this.prospect);
          if (this.planId === 'NONE') {
            this.subscriptionService
              .createSubscription()
              .subscribe(s => {
                this.router.navigateByUrl('/dashboard/confirmation');
              });
          } else {
            this.router.navigateByUrl('dashboard/payment?planId=' + this.planId + '&yearly=' + this.yearly);
          }
        }
      );
  }

  private _filterCountry(value: string): string[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.states.map(state => state.name)
      .filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  public viewCompagnyDetails(country: string): boolean {
    if (!country) {
      return false;
    }
    return this.countriesService
      .getStates()
      .find(state => state.name === country)
      ?.eu
  }


  public viewVatDetails(country: string, type: string): boolean {
    if (!country) {
      return false;
    }

    if (country === 'France') {
      return type !== 'Auto-Entrepreneur / Micro-Entreprise';
    } else {
      return this.countriesService
        .getStates()
        .find(state => state.name === country)
        ?.eu;
    }
  }


}

import { Component, OnInit } from '@angular/core';
import {MyErrorStateMatcher} from '../login.component';
import {AbstractControl, FormBuilder, FormControl, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../api/authentication.service';
import {Router} from '@angular/router';
import {transformAll} from '@angular/compiler/src/render3/r3_ast';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  model: any = {username: '' };
  returnUrl: string;
  hide= true;
  validForm= false;
  public isMobile: boolean;


  emailFormControl = new FormControl(this.model.username, [
    Validators.required,
    Validators.email
  ]);

  public loginForm;

  matcher = new MyErrorStateMatcher();


  constructor( private formBuilder: FormBuilder,
               private authenticationService: AuthenticationService,
               private router: Router) {
  this.loginForm = this.formBuilder.group({
      email: this.emailFormControl,
    });
  }

  ngOnInit() {
    this.isMobile = (window.innerWidth <= 700);
    window.onresize = () => this.isMobile = (window.innerWidth <= 700);

  }

  resetPassword(){
    // call resert service here
    this.authenticationService
      .resetPassword(this.loginForm.get('email').value)
      .subscribe(e =>
          this.router.navigateByUrl('login/password-reset-complete')
          , error => {
        if (error.status === 404) {
          this.loginForm.controls['email'].setErrors({'unrecognized': true});
        }
        });
  }


}


<div class="detailedAnsweres" [ngClass]="{'blurred':upgradeNeeded}">
  <!--<div class="detailedAnsweres">-->

  <div *ngFor="let response of responseDetails">
    <div class="question">{{response.question}}</div>
    <div *ngFor="let selectedProposition of response.selectedPropositions " class="answer" [ngClass]="{'green': selectedProposition.correct, 'red': !selectedProposition.correct}">
      <i class="material-icons">
        {{selectedProposition.correct ? 'check' : 'close'}}
      </i>
      {{selectedProposition.text}}
    </div>
    <div class="correctAnswerInfo" *ngIf="response.correctPropositions && response.correctPropositions.length > 0"
     >
      correct answer(s) :
      <div class="answer" *ngFor="let correctProposition of response.correctPropositions">
        {{correctProposition}}
      </div>

    </div>
  </div>
</div>

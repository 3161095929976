<mat-card  class="plan-card" tabindex="-1" >
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <h3>Résumé de la transaction</h3>
    </mat-card-header>
    <mat-card-content *ngIf="finalSubsciption.purchase" >
      <p><b> Date de souscription : </b> {{finalSubsciption.subscriptionDate}} </p>
      <p><b> Date de fin : </b> {{finalSubsciption.endDate}} </p>
      <!--<p><b> Montant payé : </b> {{finalSubsciption.purchase.amount}} EUR</p>-->
      <p>Merci d'avoir fait confiance à SkilloMetrics.</p>
      <p>Pour récupérer votre facture merci de contacter admin@skillometrics.com</p>

    </mat-card-content>
    <mat-card-content *ngIf="!finalSubsciption.purchase">
      <p><b>subscription Date :</b>{{finalSubsciption.subscriptionDate}}</p>
     <p><b> end Date : </b>{{finalSubsciption.endDate}}<p>
     <p> {{finalSubsciption?.plan?.description}}<p>

    </mat-card-content>
    <mat-card-actions>
      <button mat-button
              class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect
                    mdl-button--colored" (click)="goToCatalogue()"  >
        Consulter le Catalogue
      </button>
    </mat-card-actions>
  </mat-card>

<mat-card *ngIf="clientForm" class="plan-card" tabindex="-1">
  <mat-card-content>
    <form name="clientForm" [formGroup]="clientForm"
          (ngSubmit)="clientForm.valid && saveClient()"
          class="example-form"  novalidate>
      <div class="cntt">
        <mat-form-field class="example-full-width">
          <input type="text" placeholder="Pays" aria-label="Number" matInput formControlName="country" [matAutocomplete]="auto">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="countryFormControl.hasError('required')">
            Le pays de résidence fiscale est <strong>obligatoire</strong> pour des raisons comptables.
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="countryFormControl.value === 'France' " class="example-full-width">
            <mat-select  placeholder="Type de société" formControlName="companyType" >
              <mat-option *ngFor="let companyTypeOption of companyTypes" [value]="companyTypeOption.name">
                {{companyTypeOption.name}}
              </mat-option>
            </mat-select>
          <mat-error *ngIf="countryFormControl.value=== 'France' && companyTypeFormControl.hasError('required')">
            Le type de société est <strong>obligatoire</strong> pour des raisons comptables.
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="viewCompagnyDetails(countryFormControl.value)" class="example-full-width">
          <input matInput placeholder="Nom de Société" type="company" id="company"  name="company"  formControlName="company">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="Nom" type="lastName" id="lastame"  name="lastName"  formControlName="lastName">
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Prénom" type="firstName" id="name"  name="name"  formControlName="name">
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Email" type="email" id="email"  name="email"  formControlName="email">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="Numéro de téléphone" type="phoneNumber" id="phoneNumber"  name="phoneNumber"  formControlName="phoneNumber">
        </mat-form-field>



        <mat-checkbox name="tosAccepted"  formControlName="tosAccepted">J'accepte les termes et conditions d'usage de skillometrics</mat-checkbox>
        <mat-error *ngIf="tosFormControl.hasError('required')">
         Vous ne pouvez pas souscrire tant que vous n'avez pas accepté <strong>les conditions d'usage</strong> de nos services.
        </mat-error>

      </div>
    </form>
  </mat-card-content>
  <mat-card-actions class="shift-right">
    <button disabled="{{!validForm}}" mat-button
            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect
                    mdl-button--colored" (click)="saveClient()"  >
      Enregistrer
    </button>
  </mat-card-actions>
</mat-card>

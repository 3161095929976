<div class="mdl-layout__header-row">
      <button [hidden]="!isBackToResultList" routerLink="./resultlist" mat-icon-button>
        <mat-icon>keyboard_backspace</mat-icon>
      </button>
      <span class="mdl-layout-title header-title">{{title}}</span>
      <div class="mdl-layout-spacer"></div>
      <div [hidden]="!isSearcheable" class="mdl-textfield mdl-js-textfield mdl-textfield--expandable">
        <label class="mdl-button mdl-js-button mdl-button--icon" for="search">
              <i class="material-icons">search</i>
            </label>
        <div  class="mdl-textfield__expandable-holder">
          <input class="mdl-textfield__input" type="search" id="search" [formControl]="queryField">
          <label class="mdl-textfield__label" for="search">Enter your query...</label>
        </div>
      </div>
      <button class="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--icon" id="hdrbtn">
            <i class="material-icons">more_vert</i>
          </button>
      <ul class="mdl-menu mdl-js-menu mdl-js-ripple-effect mdl-menu--bottom-right" for="hdrbtn">
        <li class="mdl-menu__item">A propos</li>
        <li class="mdl-menu__item">Contacts</li>
        <li class="mdl-menu__item">Informations Légales</li>
      </ul>
    </div>

import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const jwt = localStorage.getItem('token');
        if (jwt) {
            request = request.clone({
                url : request.url,
                setHeaders: {
                    Authorization: `Bearer ${jwt}`,
                    ContentType: 'application/json'
                }
            });
        }else{
            request = request.clone({
                url : request.url,
                setHeaders: {
                    ContentType: 'application/json'
                }
            });
        }


        return next
          .handle(request)
          .pipe() as any;
    }
}

<mat-card *ngIf="planId === 'SOLO'" class="payment-card">
  <mat-card-header>
    <div mat-card-avatar ></div>
    <mat-card-title>Solo</mat-card-title>
    <mat-card-subtitle>Plan mono utilisateur</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p>
        <span style ="font-size: 36px; font-family: bold;" >
        {{totalAmount}}
      </span>€ par {{per}} hors taxes</p>
  </mat-card-content>
  <mat-card-actions class="shift-right">
    <button id="checkout-button" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect  mdl-button--colored"
             mat-button>Payer</button>
  </mat-card-actions>
</mat-card>

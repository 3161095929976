import { Component, OnInit, Inject, ViewChild} from '@angular/core';
import { TestlistComponent } from 'app/dashboard/testlist/testlist.component';
import { User } from 'app/api/api.model';
import {MAT_CHECKBOX_DEFAULT_OPTIONS} from '@angular/material/checkbox';
import {MatDialog,MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { UserService } from 'app/api/user.service.component';
import { InvitationService } from 'app/api/invitation.service.component';
import { Configuration } from 'app/api/api.configuration.component';
import { TestService } from 'app/api/test.service.component';
import {Invitation} from 'app/api/api.model';
import {MatCheckboxModule} from '@angular/material/checkbox';

@Component({
  selector: 'app-send-invitation-dialog',
  providers: [Configuration,
    TestService,
    UserService,
    InvitationService,
    {provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: 'check'}],
  templateUrl: './send-invitation-dialog.component.html',
  styleUrls: ['./send-invitation-dialog.component.css']
})
export class SendInvitationDialogComponent implements OnInit {

  myUser: User;
  testId: number;
  clientId: number;

  private candidatList: User[];
  public candidatCheckBoxes : Array<{candidat: User, checked: boolean}> = [];
  displayedColumns = ['checked', 'email', 'lastname'];
  dataSource : MatTableDataSource<{candidat: User, checked: boolean}>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
   parentComp: TestlistComponent;


  ngOnInit() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userService.getAllMyCandidats().subscribe(c => {
      this.candidatList = c;
      for (let x = 0; x < this.candidatList.length  ; x++){
        this.candidatCheckBoxes.push({candidat: this.candidatList[x], checked: false});
      }
      this.dataSource = new MatTableDataSource<{candidat: User, checked: boolean}>(this.candidatCheckBoxes);
      this.dataSource.paginator = this.paginator;
    });
  }




  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
               private userService: UserService,
               private invitationService : InvitationService) {
    this.myUser = new User();
    this.testId = data.testId;
    this.parentComp = data.parentComp;
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.clientId = currentUser;
  }

  sendInvitations() {
    this.getSelectedCandidats().forEach(candidat => this.userService.inviteUser(this.testId, candidat.id)
      .subscribe(i => {
      this.invitationService.sendInvitationEmail(this.clientId, i.token).subscribe(r => {
        this.parentComp.dialog.closeAll();
      });
    })
    );
  }

public getSelectedCandidats(): Array<User> {
    return this.candidatCheckBoxes
              .filter(opt => opt.checked)
              .map(opt => opt.candidat);
  }

 public  noneIsSelected(): boolean {
   return !this.candidatCheckBoxes.some(candidat => candidat.checked);
  }

}

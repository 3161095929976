import {Injectable} from '@angular/core';
import {Configuration} from './api.configuration.component';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Claim} from './api.model';
/**
 * Created by walid on 19/02/20.
 */

@Injectable()
export class ClaimService {

  private actionUrl: string;

  constructor(private httpClient: HttpClient, private conf: Configuration) {
    this.actionUrl = conf.ServerWithApiUrl + 'claim';
  }


  public createClaim(claim: Claim ): Observable<Claim> {
    return this.httpClient.post<Claim>(this.actionUrl, claim);
  }

}

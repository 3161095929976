import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClaimCreationDialogComponent } from './claim-creation-dialog/claim-creation-dialog.component';
import { ClaimService } from '../../api/claim.service';
import { Claim } from '../../api/api.model';
import { SubscriptionService } from "../../api/subscription.service";

@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './frequently-asked-questions.component.html',
  styleUrls: ['./frequently-asked-questions.component.css']
})
export class FrequentlyAskedQuestionsComponent implements OnInit {

  public showActions: boolean;

  constructor(public dialog: MatDialog,
    public claimService: ClaimService,
    public snackBar: MatSnackBar,
    public subscriptionService: SubscriptionService) { }

  ngOnInit() {
    this.subscriptionService.getSubscriptionsByClientId()
      .subscribe(
        subscription => {
          this.showActions = subscription ? true : false;
        });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ClaimCreationDialogComponent, {
      height: '600px',
      width: '600px',
      panelClass: 'formFieldWidth480',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.claimService.createClaim(result as Claim)
          .subscribe(
            createdClaim => {
              this.snackBar.open('Votre demande a été bien enregistrée. Elle sera traitée dans les plus brefs délais.', '', {
                duration: 3000
              });
            }
          );
      }
    });
  }

}

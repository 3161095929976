<div class="fab"  >
  <form class='cntt-wrapper' [formGroup]="addCandidatForm" (ngSubmit)="addCandidatForm.valid && saveUser()" class="example-form"  novalidate>
    <div id="fab-hdr">
      <h3>{{dialogTitle}}</h3>
    </div>

    <div class="cntt">

      <mat-form-field class="example-full-width">
        <input matInput placeholder="Email" type="email" id="email"  name="email" [(ngModel)]="myUser.email" [formControl]="emailFormControl" [errorStateMatcher]="matcher" >
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input matInput placeholder="Nom" type="lastname" id="lastname"  name="lastname" [(ngModel)]="myUser.lastname" formControlName="lastname">
      </mat-form-field>


      <mat-form-field class="example-full-width">
        <input matInput placeholder="Prénom" type="firstname" id="firstname"  name="firstname" [(ngModel)]="myUser.firstname" formControlName="firstname">
      </mat-form-field>

    </div>

    <div class="btn-wrapper">
      <button class="mdl-button mdl-js-button" [mat-dialog-close]="true" id="cancel">Annuler</button>
      <button class="mdl-button mdl-js-button mdl-button--primary" id="submit" [disabled]="!addCandidatForm.valid">Envoyer</button>
    </div>

  </form>
</div>

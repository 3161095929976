
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import {Periodicity, Subscription} from 'app/api/api.model';

@Injectable()
export class SubscriptionWorkflowService {

private prospect = new BehaviorSubject<Prospect>(new Prospect());

setProspect(prospect: Prospect) {
    this.prospect.next(prospect);
}

getProspect(): Observable<Prospect>  {

    return this.prospect.asObservable();
}


}

export class Prospect {
     clientId: number;
     planId: number;
     planPeriodicity: string;
     vatApplicable: boolean;
     finalSubsciption: Subscription;
}

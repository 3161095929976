


import { Injectable } from '@angular/core';
import { SubscriptionService } from 'app/api/subscription.service';
import { Observable } from 'rxjs';
import {Configuration} from "./api.configuration.component";
import {HttpClient} from "@angular/common/http";
import {Jurisdiction} from "./api.model";

@Injectable()
export class PermissionService {

  private actionUrl: string;


  constructor(private httpClient: HttpClient, private conf: Configuration) {
    this.actionUrl = conf.ServerWithApiUrl + 'client/jurisdiction';
  }


  public getPermissions(): Observable<Jurisdiction> {
    return this.httpClient.get<Jurisdiction>(this.actionUrl);
  }





}

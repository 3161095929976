
<div class="example-container mat-elevation-z8">
  <mat-table #table [dataSource]="dataSource" matSort matSortActive="creationDate" matSortDirection="desc">

    <!-- Position Column -->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef   mat-sort-header> Email </mat-header-cell>
      <mat-cell *matCellDef="let invitation"> {{invitation.email}} </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="lastname">
      <mat-header-cell [hidden]="isMobile" *matHeaderCellDef   mat-sort-header> Nom </mat-header-cell>
      <mat-cell [hidden]="isMobile" *matCellDef="let invitation"> {{invitation.lastname}} </mat-cell>
    </ng-container>

    <!-- thema Column -->
    <ng-container matColumnDef="testThema">
      <mat-header-cell [hidden]="isMobile" *matHeaderCellDef   mat-sort-header> Thèmatique </mat-header-cell>
      <mat-cell [hidden]="isMobile" *matCellDef="let invitation"> {{invitation.testThema}} </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="creationDate">
      <mat-header-cell [hidden]="isMobile" *matHeaderCellDef  mat-sort-header> Date d'invitation </mat-header-cell>
      <mat-cell [hidden]="isMobile" *matCellDef="let invitation"> {{invitation.creationDate | date: 'dd/MM/yyyy HH:mm' }} </mat-cell>
    </ng-container>



    <!-- Name Column -->
    <ng-container matColumnDef="link">
      <mat-header-cell *matHeaderCellDef> Lien </mat-header-cell>
      <mat-cell *matCellDef="let invitation">
        <button mat-icon-button
          (click)="openSnackBar(invitation.token)">
           <i class="material-icons">link</i>
        </button> </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator #paginator
                 [pageSize]="5"
                 [pageSizeOptions]="[5, 10, 20]"
                 [showFirstLastButtons]="true">
  </mat-paginator>
</div>

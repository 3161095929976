import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'stackChip'
})

export class StackChipPipe implements PipeTransform {
  transform( query: string): string[] {
    if (!query) {
      return [];
    }

    return query.split(' ');
  }
}

<h1 mat-dialog-title>Suspension de la souscription</h1>

<div mat-dialog-content>
  <p>
  Cette action est irréversible. Vous perdez accès au catalogue dans 24h
  Ceci ne garantit pas le remboursement.
  Il faudra faire une demande par email dans la section FAQ, sous réserve de respect aux conditions d'utilisation et de remboursement.
  </p>
  <p>
  <b>Voulez vous vraiment sûr de vouloir annuler votre souscription ?</b>
  </p>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="secondary" [mat-dialog-close]="true">Suspendre la souscription</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="false">Annuler</button>
</div>

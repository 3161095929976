import { Component, OnInit } from '@angular/core';
import {InvitationService  } from '../api/invitation.service.component';
import {Configuration  } from '../api/api.configuration.component';
import {Invitation} from '../api/api.model';
import { Router , ActivatedRoute} from '@angular/router';
import { AuthenticationService } from 'app/api/authentication.service';
@Component({
  selector: 'app-invitation',
   providers : [Configuration , InvitationService],
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.css'],
})
export class InvitationComponent implements OnInit {
  token: string;
  invitation: Invitation;

  constructor(private invitationService: InvitationService,
   router: ActivatedRoute,
   private authService: AuthenticationService) {
    this.token = router.snapshot.params['token'];
   }

  ngOnInit() {
    this.authService.otpLogin(this.token).subscribe(auth => {

        this.invitationService.getByToken(this.token).subscribe(t => {
            this.invitation = t;
        });
      });

  }

}

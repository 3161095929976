import {Component, OnInit} from '@angular/core';
import {TestService} from '../../api/test.service.component';
import {TestSummary} from '../../api/api.model';
import {MatDialog} from '@angular/material/dialog';
import {SendInvitationDialogComponent} from 'app/dashboard/testlist/send-invitation-dialog/send-invitation-dialog.component';
import {AddCandidatDialogComponent} from 'app/dashboard/testlist/add-candidat-dialog/add-candidat-dialog.component';
import {SearchService} from 'app/dashboard/testlist/search.service';
import {PermissionService} from '../../api/permission.service';

@Component({
  selector: 'app-testlist',
  templateUrl: './testlist.component.html',
  // providers : [Configuration , TestService,UserService],
  styleUrls: ['./testlist.component.css']
})
export class TestlistComponent implements OnInit   {

  selectedOption: string;
  list: Array<TestSummary> = [];
  searchQuery= '';
  subscriptionStatus: boolean;

    constructor(private permissionService: PermissionService,
       private _dataService: TestService,
       public searchService: SearchService,
       public dialog: MatDialog) {
       // localStorage.setItem('currentUser', JSON.stringify({ token: "token", name: "name" ,id : 1 }));
    };


  ngOnInit() {
    this._dataService.getAll().subscribe(t => {
     this.list = t;

    });

    this.searchService.getSearchData().subscribe(q => {
      this.searchQuery = q;
    });

    this.updateJurisdiction();
  }

    openDialog(testId: number) {
    const dialogRef = this.dialog.open(AddCandidatDialogComponent,  {
      data: { testId: testId , parentComp : this},
      height: '400px',
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updateJurisdiction();
    });
  }

  private updateJurisdiction() {
    this.permissionService
      .getPermissions()
      .subscribe(jurisdiction => {
        this.subscriptionStatus = jurisdiction.open;
        if (jurisdiction.invitationNumber && jurisdiction.invitationNumber >= 3) {
          this.subscriptionStatus = false;
        }
      });
  }
  sendInvitation(testId: number) {
    const dialogRef = this.dialog.open(SendInvitationDialogComponent,  {
      data: { testId: testId , parentComp : this},
      height: '550px',
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updateJurisdiction();
    });
  }


  onFieldChange(ev){
    this.searchQuery = ev.target.value;
  }


  comptueLevel(level) {
    if(level === 'BEGINNER') {
      return 'beginner';
    } else if(level === 'INTERMEDIATE') {
      return 'intemidiate';
    } else if (level === 'ADVANCED') {
      return 'advanced';
    }

  }

  comptueNiveau(level) {
    if(level === 'BEGINNER') {
      return 'Débutant';
    } else if(level === 'INTERMEDIATE') {
      return 'Intermédiaire';
    } else if (level === 'ADVANCED') {
      return 'Avancé';
    }

  }
}

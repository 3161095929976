import {AfterViewChecked, Component, OnInit, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import { TestService} from '../api/test.service.component';
import { InvitationService} from '../api/invitation.service.component';
import {Configuration} from '../api/api.configuration.component';
import { Test, TestResponse, QuestionResponse, Passage, Invitation, Question } from '../api/api.model' ;
import { Router , ActivatedRoute} from '@angular/router';
 declare var PR: any;

@Component({
  selector: 'app-root',
  providers : [Configuration , TestService, InvitationService ],
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.css']
})


export class RoomComponent implements  OnInit, AfterViewChecked {
  title = 'app works!';
  myTest: Test;
  testId : number;
  token : string;
  public carouselOne: any;
  public myResponse : TestResponse = {
    reponseMap: new Map<number, QuestionResponse>()
  };
  public code: string;
  public currentQuestion: Question;
  public currentQIndex: number;
  public numberOfQuestions: number;

  constructor(private _dataService: TestService,
    private _invitationService : InvitationService,
    private router: Router,  activatedRoute: ActivatedRoute) {
    this.testId = activatedRoute.snapshot.params['id'];
    this.token = activatedRoute.snapshot.queryParams['token'];

    this._invitationService.getByToken(this.token).subscribe( t => {
      const invita = t;
      if (invita.consumed){
        this.router.navigateByUrl('invitation/' + this.token);
      }
    });
  }

    public ngAfterViewChecked(): any {
    PR.prettyPrint();
  }

  ngOnInit() {

    this._dataService
      .getById( this.testId)
      .subscribe(t => {
      this.myTest = t;
      this.currentQuestion = t.questions[0];
      this.numberOfQuestions = t.questions.length;
      this.currentQIndex = 0;
        this.myTest.questions.forEach((question) => {
          const qr: QuestionResponse = {
            questionID: question.questionID,
            sequenceID: question.sequenceID,
            selectedPropositionID: null,
            propositions: new Set<String>()
          };
          this.myResponse.reponseMap.set(question.sequenceID, qr);
        });
      });

       this.carouselOne = {
      grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
      slide: 1,
      speed: 400,
      point: {
        visible: true
      },
      load: 2,
      touch: true,
      loop: false,
      custom: 'banner'
    };
  }

  public myfunc(event: Event) {
     // carouselLoad will trigger this funnction when your load value reaches
     // it is helps to load the data by parts to increase the performance of the app
     // must use feature to all carousel
  }

  public clicked(id: string, token: string, resp: TestResponse): void {
     this._dataService.postReponse(id, token, resp).subscribe(t => {
       this.router.navigateByUrl('testroom/closed');
     });

  }

  public checkCompleted(resp : TestResponse): boolean {
   let result = true;
  resp.reponseMap.forEach(element => {
    if (!element.selectedPropositionID || element.selectedPropositionID == undefined || element.selectedPropositionID.length <= 0 ){
      result = false;
    }
  });
    return result;
  }



  public next() {
    if(this.currentQIndex < this.myTest.questions.length-1) {
    this.currentQIndex = this.currentQIndex + 1;
    this.currentQuestion = this.myTest.questions[this.currentQIndex];
    }
  }

  public previous() {
    if(this.currentQIndex > 0 ) {
    this.currentQIndex = this.currentQIndex - 1;
    this.currentQuestion = this.myTest.questions[this.currentQIndex];
    }
  }

  protected readonly Math = Math;

  public computeProgress() {
   return  Math.floor( (this.currentQIndex+1) / this.numberOfQuestions  * 100);
  }
}

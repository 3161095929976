import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../api/user.service.component';
import { User } from '../../api/api.model';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddCandidatDialogComponent } from '../testlist/add-candidat-dialog/add-candidat-dialog.component';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-candidatlist',
  templateUrl: './candidatlist.component.html',
  styleUrls: ['./candidatlist.component.css']
})
export class CandidatlistComponent implements OnInit {
  public isMobile: boolean;
  private candidatList: User[];
  displayedColumns = ['email', 'lastname', 'creationDate', 'actions'];
  dataSource: MatTableDataSource<User>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  private currentUser;
  constructor(private userService: UserService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.updateTable();
    this.isMobile = (window.innerWidth <= 700);
    window.onresize = () => this.isMobile = (window.innerWidth <= 700);
  }

  updateTable() {
    this.userService.getAllMyCandidats()
      .pipe(take(1))
      .subscribe(c => {
        this.candidatList = c;
        this.dataSource = new MatTableDataSource<User>(this.candidatList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  addCandidat() {
    const dialogRef = this.dialog.open(AddCandidatDialogComponent, {
      data: { parentComp: this },
      height: '400px',
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.userService.getAllMyCandidats().subscribe(
        c => this.updateTable()
      );
    });
  }

  delete(candidat: User) {
    this.userService.deleteCandidat(candidat.id)
      .subscribe(any => this.updateTable());
  }

  modify(candidat: User) {
    const dialogRef = this.dialog.open(AddCandidatDialogComponent, {
      data: {
        parentComp: this,
        candidat: candidat
      },
      height: '400px',
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.userService.getAllMyCandidats().subscribe(
        c => this.updateTable()
      );
    });
  }

}

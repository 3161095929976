import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {PlanService} from '../../api/plan.service.component';
import {Configuration} from '../../api/api.configuration.component';
import { Plan, Periodicity } from 'app/api/api.model';
import { SubscriptionWorkflowService, Prospect } from 'app/api/subscription.workflow.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriptionService } from '../../api/subscription.service';
import { PermissionService } from '../../api/permission.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {environment} from '../../../environments/environment';
declare var Stripe: any;

@Component({
  selector: 'app-plan-selection',
  templateUrl: './plan-selection.component.html',
  styleUrls: ['./plan-selection.component.css'],
  providers : [Configuration]
})
export class PlanSelectionComponent implements OnInit, OnDestroy {

 planList: Plan[];
 someProspect: Prospect;
 public yearly = true;
 public offerFreePlan = false;
 public mainPlanEquivalentPrice;
 public mainPlan: Plan = new Plan();

 constructor(private planService: PlanService,
             private workflow: SubscriptionWorkflowService,
             private subscriptionService: SubscriptionService,
             private permissionService: PermissionService ,
             public router: Router,
             private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.workflow.getProspect().subscribe(prospect => {
      if (!prospect.clientId) {
        prospect.clientId = parseInt(localStorage.getItem('currentUser'), 10 );
      }
      this.someProspect = prospect;
      this.planService.getPlansByClientId(prospect.clientId, false)
        .subscribe(list => {
          this.planList = list;
          this.offerFreePlan = this.planList.some(plan => plan.name === 'test');
          this.mainPlan = this.planList.filter(plan => plan.name === 'SOLO')[0];
          this.mainPlanEquivalentPrice = this.mainPlan.price;
          this.updatePlan();

        });
    } ).unsubscribe();
  }

  ngOnDestroy() {
   // this.workflow.getProspect().;
  }

  public toggle(event: MatSlideToggleChange) {
    this.yearly = event.checked;
    this.updatePlan();
  }

  private updatePlan() {
    if (this.yearly) {
      this.mainPlanEquivalentPrice = this.mainPlan.monthlyEquivalentPrice;
    } else {
      this.mainPlanEquivalentPrice = this.mainPlan.price;
    }
  }

  public formatPeriodicity() {
   return this.yearly ? 'an' : 'mois';
  }


    chooseOffer(selectedPlan: string) {
      this.router.navigateByUrl('/dashboard/client-info?planId=' + selectedPlan + '&yearly=' + this.yearly);
    }

  public ngAfterViewInit____() {
    const stripe = Stripe(environment.stripePublicKey);
    const checkoutButton = document.getElementById('checkout-button');
    const self = this;

    checkoutButton.addEventListener('click', function(event) {
      // Create a new Checkout Session using the server-side endpoint you
      // created in step 3.
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', `Bearer ` + localStorage.getItem('token'));

      const paymentQuery = {
        planId: this.dataset.id,
        yearly : self.yearly,
      };
      fetch('api/payment/create-checkout-session', {
        method: 'POST',
        headers : myHeaders,
        body : JSON.stringify(paymentQuery)
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(session) {
          return stripe.redirectToCheckout({ sessionId: session.id });
        })
      .then(function(result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          if (result.error) {
            alert(result.error.message);
          }
        })
        .catch(function(error) {
        });
    });
  }


}

<div class="demo-layout mdl-layout mdl-js-layout mdl-layout--fixed-drawer mdl-layout--fixed-header">
  <app-header class="demo-header mdl-layout__header mdl-color--grey-100 mdl-color-text--grey-600">
  </app-header>
  <div class="demo-drawer mdl-layout__drawer mdl-color--blue-grey-900 mdl-color-text--blue-grey-50">
    <header class="demo-drawer-header">
      <div class="demo-avatar-dropdown">
        <span class="email-holder">{{connectedClientEmail}}</span>
        <div class="mdl-layout-spacer"></div>
        <button id="accbtn" class="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--icon">
              <i class="material-icons" role="presentation">arrow_drop_down</i>
              <span class="visuallyhidden">Accounts</span>
            </button>
        <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect" for="accbtn">
          <li class="mdl-menu__item" (click)="logout()">Se déconnecter</li>
          <li class="mdl-menu__item" routerLink="./my-subscriptions" routerLinkActive="active">Mes souscriptions</li>
        </ul>
      </div>
    </header>
    <nav class="demo-navigation mdl-navigation mdl-color--blue-grey-800">
      <a class="mdl-navigation__link listEl" routerLink="./testlist" routerLinkActive="active"><i class="mdl-color-text--blue-grey-400 material-icons whity" role="presentation">dashboard</i>Catalogue</a>
      <a class="mdl-navigation__link listEl" routerLink="./invitationlist" routerLinkActive="active"><i class="mdl-color-text--blue-grey-400 material-icons whity" role="presentation">feedback</i>Invitations</a>
      <a class="mdl-navigation__link listEl" routerLink="./resultlist" routerLinkActive="active"><i class="mdl-color-text--blue-grey-400 material-icons whity" role="presentation">flag</i>Résultats</a>
      <a class="mdl-navigation__link listEl" routerLink="./candidatlist" routerLinkActive="active"><i class="mdl-color-text--blue-grey-400 material-icons whity" role="presentation">people</i>Candidats</a>
      <a class="mdl-navigation__link listEl" routerLink="./plans" routerLinkActive="active"><i class="mdl-color-text--blue-grey-400 material-icons whity" role="presentation">category</i>Plans</a>
      <div class="mdl-layout-spacer"></div>
      <a class="mdl-navigation__link listEl" routerLink="./faq" routerLinkActive="active"><i class="mdl-color-text--blue-grey-400 material-icons whity" role="presentation">help_outline</i>F.A.Q</a>
    </nav>
  </div>
 <div class="mdl-layout__content">
   <div class="page-content">
      <router-outlet></router-outlet>
    </div>
</div>

</div>
